import React, { useState, useEffect } from "react";
import {
  ChevronDown,
  // Activity,
  // Eye,
  FileText,
  Image,
  AlignLeft,
  Code,
} from "lucide-react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
// import { format } from "date-fns";
import { toast } from "react-hot-toast";
import ReactQuill from "react-quill";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import "react-quill/dist/quill.snow.css";
import "tailwindcss/tailwind.css";
import { getTag } from "../../actions/master/tag";
import { getParentCategory } from "../../actions/master/parentCategory";
import { getCategory } from "../../actions/master/category";
import { addBlog, checkBlogSlug } from "../../actions/blog/blog";
import Loader from "../../shared/Loader";

const PostPage = () => {
  const dispatch = useDispatch();
  // const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  // const [selectedLanguage, setSelectedLanguage] = useState("English");
  // const [saveDropDown, setSaveDropDown] = useState(false);
  // const [dropdownVisible, setDropdownVisible] = useState(false);
  // const [selectedProfile, setSelectedProfile] = useState(profiles[0]);
  const [activeTab, setActiveTab] = useState("content");
  const [selectedOption, setSelectedOption] = useState(null);
  const [editorContent, setEditorContent] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedParent, setSelectedParent] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [additionalImage, setAdditionalImage] = useState([]);
  const [isPublished, setIsPublished] = useState(false);
  const [slugError, setSlugError] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [image, setImage] = useState(null);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [postDate, setPostDate] = useState("");

  // if (loading) {
  //   return (
  //     <div className="flex items-center justify-center h-full">
  //       {" "}
  //       <Loader />
  //     </div>
  //   );
  // }

  const generateSlug = (name) => {
    if (!name) return "";

    return name
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-");
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .min(3, "Title must be at least 3 characters long")
      .required("Title is required"),
    editorContent: Yup.string().required("Content is required."),
    readTime: Yup.string().required("Read time is required"),
    selectedDate: Yup.date().required("Post date is required"),
    selectedTime: Yup.string().required("Time is required"),
    selectedImage: Yup.mixed().required("Image is required"),
    // description: Yup.string()
    //   .optional("Please enter your description.")
    //   .min(20, "Your description must be at least 20 characters long."),
  });

  const combineDateAndTime = () => {
    if (startDate && selectedTime) {
      const combinedDateTime = new Date(startDate);
      const timeHours = selectedTime.getHours();
      const timeMinutes = selectedTime.getMinutes();
      const timeSeconds = selectedTime.getSeconds();

      combinedDateTime.setHours(timeHours, timeMinutes, timeSeconds, 0);

      return combinedDateTime.toISOString(); // Returns ISO formatted string
    }
    return "";
  };

  // Use useEffect to update postDate whenever the selectedDate or selectedTime changes
  useEffect(() => {
    const combined = combineDateAndTime();
    setPostDate(combined);
  }, [startDate, selectedTime]);

  const handleSubmit = async (values, { resetForm }) => {
    if (slugError) return;

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("slug", values.slug);
      formData.append("content", values.editorContent);
      formData.append("readTime", values.readTime);
      formData.append("FeaturedPic", image);
      formData.append("publishDate", postDate);
      formData.append("status", isPublished ? "Published" : "Draft");
      if (selectedCategories && selectedCategories.length > 0) {
        const transformedCategories = selectedCategories.map((category) => ({
          id: category.value,
          slug: category.label,
        }));
        transformedCategories.forEach((category, index) => {
          formData.append(`categorys[${index}][slug]`, category.slug);
          formData.append(`categorys[${index}][id]`, category.id);
        });
      }
      if (selectedOption && selectedOption.length > 0) {
        const transformedTags = selectedOption.map((tag) => ({
          id: tag.value,
          slug: tag.label,
        }));
        transformedTags.forEach((tag, index) => {
          formData.append(`tags[${index}][slug]`, tag.slug);
          formData.append(`tags[${index}][id]`, tag.id);
        });
      }
      if (additionalImages && additionalImages.length > 0) {
        additionalImages.forEach((image) => {
          formData.append("AdditionalPic", image);
        });
      }
      if (values.metaTitle) {
        formData.append("metaTag", values.metaTitle);
      }
      if (values.description) {
        formData.append("description", values.description);
      }
      // Log all formData content
      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }
      const res = await dispatch(addBlog(formData));
      console.log(res);
      if (res.success) {
        toast.success(res.message);
        resetForm();
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (event) => {
    setIsPublished(event.target.checked);
  };

  const handleTagChange = (option) => {
    setSelectedOption(option);
  };

  const handleChange = (selectedOptions) => {
    setSelectedParent(selectedOptions);
  };

  const handleChildChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await dispatch(getTag());
        if (res.success) {
          const data = res.data.map((item) => ({
            value: item.id,
            label: item.slug,
          }));
          setOptions(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error(error?.response?.data?.message || "Something went wrong!");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  // console.log("options",options)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await dispatch(getParentCategory());
        if (res.success) {
          const data = res.data.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setParentCategories(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error(error?.response?.data?.message || "Something went wrong!");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchChildCategories = async () => {
      if (!selectedParent) return; // No need to fetch if no parent is selected

      try {
        setLoading(true);
        const res = await dispatch(getCategory());
        console.log(res);
        if (res.success) {
          const filteredCategories = res?.data?.filter((item) => {
            return (
              item.pCategory && item.pCategory.name === selectedParent?.label
            );
          });

          const data = filteredCategories.map((item) => ({
            value: item.id,
            label: item.slug,
          }));
          setCategories(data);
        }
      } catch (error) {
        console.error("Error fetching child categories:", error);
        toast.error(error?.response?.data?.message || "Something went wrong!");
      } finally {
        setLoading(false);
      }
    };

    fetchChildCategories();
  }, [dispatch, selectedParent]);

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  const checkSlugAvailability = debounce(async (slug) => {
    try {
      const res = await dispatch(checkBlogSlug({ slug }));
      setSlugError(""); // Clear error if slug is available
    } catch (error) {
      setSlugError(error?.response?.data?.message || "Something went wrong!");
    }
  }, 2000);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // const toggleDropdown = () => {
  //   setIsDropdownVisible(!isDropdownVisible);
  // };

  // const handleLanguageSelect = (language) => {
  //   setSelectedLanguage(language);
  //   setIsDropdownVisible(false);
  // };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      setSelectedImage(URL.createObjectURL(file)); // Preview the selected image
    }
  };

  const handleAdditionalImage = (event) => {
    const files = Array.from(event.target.files);
    setAdditionalImages(files);
    const newImages = files.map((file) => ({
      file,
      url: URL.createObjectURL(file), // Create a URL for the image preview
    }));

    // Check if adding these images would exceed the 20-image limit
    if (additionalImage.length + newImages.length > 20) {
      alert("You can only upload up to 20 images.");
    } else {
      setAdditionalImage((prevImages) => [...prevImages, ...newImages]);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          title: "",
          editorContent: "",
          readTime: "",
          selectedDate: null,
          selectedTime: null,
          selectedImage: null,
          metaTitle: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            <div className="intro-y mt-8 flex flex-col items-center sm:flex-row">
              <h2 className="mr-auto text-lg font-medium font-space-grotesk">
                Add New Post
              </h2>
              <div className="mt-4 flex w-full sm:mt-0 sm:w-auto">
                {/* <div className="dropdown relative mr-2">
                  <button
                    onClick={toggleDropdown}
                    aria-expanded={isDropdownVisible}
                    className="transition duration-200 border shadow-sm items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed !box flex"
                  >
                    {selectedLanguage}
                    <ChevronDown className="stroke-1.5 ml-2 h-4 w-4" />
                  </button>
                  {isDropdownVisible && (
                    <div className="dropdown-menu absolute z-[9999]">
                      <div className="dropdown-content rounded-md border-transparent bg-white p-2 shadow-[0px_3px_10px_#00000017] dark:border-transparent dark:bg-darkmode-600 w-40">
                        <a
                          className="cursor-pointer font-space-grotesk flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item"
                          onClick={() => handleLanguageSelect("English")}
                        >
                          <Activity className="stroke-1.5 mr-2 h-4 w-4" />
                          <span className="truncate font-space-grotesk">
                            English
                          </span>
                        </a>
                        <a
                          className="cursor-pointer font-space-grotesk flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item"
                          onClick={() => handleLanguageSelect("Indonesian")}
                        >
                          <Activity className="stroke-1.5 mr-2 h-4 w-4" />
                          <span className="truncate font-space-grotesk">
                            Indonesian
                          </span>
                        </a>
                      </div>
                    </div>
                  )}
                </div>
                <button
                  data-tw-merge=""
                  type="button"
                  className="transition font-space-grotesk duration-200 border shadow-sm items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed !box ml-auto mr-2 flex sm:ml-0"
                >
                  <Eye className="stroke-1.5 mr-2 h-4 w-4" />
                  Preview
                </button> */}
                <div className="dropdown relative mr-2">
                  <button
                    disabled={loading}
                    type="submit"
                    className="transition font-space-grotesk duration-200 border items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary flex shadow-md"
                  >
                    {loading ? "Saving..." : "Save"}
                  </button>
                </div>
              </div>
            </div>
            <div className="intro-y mt-5 grid grid-cols-12 gap-5">
              <div className="intro-y col-span-12 lg:col-span-8">
                <div>
                  <label htmlFor="title" className="inline-block mb-2">
                    Title
                  </label>
                  <Field
                    id="title"
                    name="title"
                    type="text"
                    placeholder="Enter Title"
                    className={`disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 intro-y !box px-4 py-3 pr-10 ${
                      errors.title && touched.title ? "border-red-500" : ""
                    }`}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue("title", value);
                      // setFieldValue("slug", generateSlug(value));
                      const generatedSlug = generateSlug(value);
                      setFieldValue("slug", generatedSlug);
                      checkSlugAvailability(generatedSlug);
                    }}
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-red-500 mt-1 text-sm"
                  />
                </div>
                <div className="mt-3">
                  <label htmlFor="slug" className="inline-block mb-2">
                    Slug
                  </label>
                  <Field
                    id="slug"
                    name="slug"
                    type="text"
                    placeholder="Enter Slug"
                    className={`w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary ${
                      errors.slug && touched.slug ? "border-red-500" : ""
                    }`}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue("slug", value);
                      checkSlugAvailability(value); // Check slug availability
                    }}
                  />

                  {slugError && (
                    <div className="text-red-500 mt-1 text-sm">{slugError}</div>
                  )}
                </div>
                <div className="intro-y box mt-5 overflow-hidden">
                  <div>
                    <ul className="border-b w-full flex flex-col border-transparent bg-slate-200 dark:border-transparent dark:bg-darkmode-800 sm:flex-row">
                      <li
                        id="content-tab"
                        className="focus-visible:outline-none -mb-px"
                      >
                        <button
                          onClick={() => handleTabChange("content")}
                          role="tab"
                          type="button"
                          className={`cursor-pointer appearance-none border border-transparent dark:text-slate-400 ${
                            activeTab === "content"
                              ? "dark:text-white bg-white font-medium dark:border-b-darkmode-600 text-primary border-transparent dark:bg-darkmode-600 dark:border-x-transparent dark:border-t-transparent"
                              : "hover:border-transparent hover:bg-transparent hover:text-slate-600 hover:dark:bg-transparent hover:dark:text-slate-300"
                          } rounded-t-md dark:border-transparent flex items-center justify-center w-full px-0 py-0 sm:w-40 text-slate-500`}
                        >
                          <span
                            className="tooltip font-space-grotesk cursor-pointer flex w-full items-center justify-center py-4"
                            aria-controls="content"
                            aria-selected={activeTab === "content"}
                          >
                            <FileText className="stroke-1.5 mr-2 h-4 w-4" />
                            Content
                          </span>
                        </button>
                      </li>
                      <li
                        id="meta-title-tab"
                        className="focus-visible:outline-none -mb-px"
                      >
                        <button
                          onClick={() => handleTabChange("meta-title")}
                          role="tab"
                          type="button"
                          className={`cursor-pointer appearance-none border border-transparent dark:text-slate-400 ${
                            activeTab === "meta-title"
                              ? "dark:text-white bg-white font-medium dark:border-b-darkmode-600 text-primary border-transparent dark:bg-darkmode-600 dark:border-x-transparent dark:border-t-transparent"
                              : "hover:border-transparent hover:bg-transparent hover:text-slate-600 hover:dark:bg-transparent hover:dark:text-slate-300"
                          } rounded-t-md dark:border-transparent flex items-center justify-center w-full px-0 py-0 sm:w-40 text-slate-500`}
                        >
                          <span
                            className="tooltip font-space-grotesk cursor-pointer flex w-full items-center justify-center py-4"
                            aria-controls="meta-title"
                            aria-selected={activeTab === "meta-title"}
                          >
                            <Code className="stroke-1.5 mr-2 h-4 w-4" />
                            Meta Title
                          </span>
                        </button>
                      </li>
                      <li
                        id="desription-tab"
                        className="focus-visible:outline-none -mb-px"
                      >
                        <button
                          onClick={() => handleTabChange("description")}
                          role="tab"
                          type="button"
                          className={`cursor-pointer appearance-none border border-transparent dark:text-slate-400 ${
                            activeTab === "description"
                              ? "dark:text-white bg-white font-medium dark:border-b-darkmode-600 text-primary border-transparent dark:bg-darkmode-600 dark:border-x-transparent dark:border-t-transparent"
                              : "hover:border-transparent hover:bg-transparent hover:text-slate-600 hover:dark:bg-transparent hover:dark:text-slate-300"
                          } rounded-t-md dark:border-transparent flex items-center justify-center w-full px-0 py-0 sm:w-40 text-slate-500`}
                        >
                          <span
                            className="tooltip font-space-grotesk cursor-pointer flex w-full items-center justify-center py-4"
                            aria-controls="description"
                            aria-selected={activeTab === "description"}
                          >
                            <AlignLeft className="stroke-1.5 mr-2 h-4 w-4" />
                            Description
                          </span>
                        </button>
                      </li>
                    </ul>

                    <div className="tab-content">
                      {activeTab === "content" && (
                        <div
                          id="content"
                          role="tabpanel"
                          aria-labelledby="content-tab"
                          className="tab-pane active p-5"
                        >
                          <div className="rounded-md border border-slate-200/60 p-5 dark:border-darkmode-400">
                            <div className="flex items-center border-b border-slate-200/60 pb-5 font-medium dark:border-darkmode-400 font-space-grotesk">
                              <ChevronDown className="stroke-1.5 mr-2 h-4 w-4" />
                              Text Content
                            </div>
                            <div className="mt-5">
                              <div className="editor">
                                {/* <p>Content of the editor.</p> */}

                                <ReactQuill
                                  value={editorContent}
                                  onChange={(value) => {
                                    setEditorContent(value);
                                    setFieldValue("editorContent", value);
                                  }}
                                  className="h-35 rounded-lg"
                                  theme="snow"
                                  modules={{
                                    toolbar: [
                                      [
                                        { header: "1" },
                                        { header: "2" },
                                        { font: [] },
                                      ],
                                      [{ list: "ordered" }, { list: "bullet" }],
                                      ["bold", "italic", "underline"],
                                      ["link", "image"],
                                      ["clean"],
                                    ],
                                  }}
                                  formats={[
                                    "header",
                                    "font",
                                    "list",
                                    "bullet",
                                    "bold",
                                    "italic",
                                    "underline",
                                    "link",
                                    "image",
                                  ]}
                                />
                              </div>
                              {touched.editorContent &&
                                errors.editorContent && (
                                  <div className="text-red-500 mt-2">
                                    {errors.editorContent}
                                  </div>
                                )}
                              {/* <div className="mt-4">
        <h3 className="text-lg font-semibold mb-2">Content Preview:</h3>
        <div
          className="border border-gray-300 p-4 rounded-lg"
          dangerouslySetInnerHTML={{ __html: editorContent }}
        ></div>
      </div>
    </div> */}
                            </div>
                          </div>
                          <div className="mt-5 rounded-md border border-slate-200/60 p-5 dark:border-darkmode-400">
                            <div className="flex items-center border-b border-slate-200/60 pb-5 font-medium dark:border-darkmode-400 font-space-grotesk">
                              <ChevronDown className="stroke-1.5 mr-2 h-4 w-4" />
                              Images
                            </div>
                            <div className="mt-5">
                              {/* <div>
                          <label
                            htmlFor="post-form-7"
                            className="inline-block font-space-grotesk mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right"
                          >
                            Caption
                          </label>
                          <input
                            id="post-form-7"
                            type="text"
                            placeholder="Write caption"
                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                          />
                        </div> */}
                              <div className="mt-3">
                                <label className="inline-block font-space-grotesk mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right">
                                  Upload Featured Image
                                </label>
                                <div className="rounded-md border-2 border-dashed pt-4 dark:border-darkmode-400">
                                  <div className="flex flex-wrap">
                                    <div className="relative  ml-5 h-24 w-24 border-2 border-dashed border-gray-300 flex items-center justify-center cursor-pointer">
                                      {selectedImage ? (
                                        <div className="image-fit zoom-in relative h-24 w-24 cursor-pointer">
                                          <img
                                            className="rounded-md "
                                            src={selectedImage}
                                            alt="selected"
                                          />
                                        </div>
                                      ) : (
                                        // Show plus sign if no image is selected
                                        <span className="text-3xl text-gray-400">
                                          +
                                        </span>
                                      )}

                                      <input
                                        type="file"
                                        accept="image/*"
                                        className="absolute inset-0 opacity-0 cursor-pointer"
                                        onChange={(event) => {
                                          setFieldValue(
                                            "selectedImage",
                                            event.target.files[0]
                                          );
                                          handleImageChange(event);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <ErrorMessage
                                    name="selectedImage"
                                    component="div"
                                    className="text-red-500 ml-5"
                                  />
                                  <div className="relative flex cursor-pointer items-center px-4 pb-4">
                                    <Image className="stroke-1.5 mr-2 h-4 w-4" />
                                    <span className="mr-1 text-primary font-space-grotesk">
                                      Upload additional image
                                    </span>

                                    <input
                                      type="file"
                                      accept="image/*"
                                      multiple
                                      onChange={handleAdditionalImage}
                                      className="absolute left-0 top-0 h-full opacity-0 cursor-pointer"
                                    />
                                  </div>
                                  <div className=" grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 lg:m-2 gap-4">
                                    {additionalImage.map((image, index) => (
                                      <div
                                        key={index}
                                        className="relative h-24 w-24 overflow-hidden rounded-md border"
                                      >
                                        <img
                                          src={image.url}
                                          alt={`selected-${index}`}
                                          className="h-full w-full object-cover"
                                        />
                                      </div>
                                    ))}
                                  </div>

                                  {/* Display message if the image count is maxed */}
                                  {additionalImage.length >= 20 && (
                                    <div className="mt-2 text-red-600">
                                      You’ve reached the maximum of 20 images.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {activeTab === "meta-title" && (
                        <div
                          id="meta-title"
                          role="tabpanel"
                          aria-labelledby="meta-title-tab"
                          className="tab-pane active p-5"
                        >
                          <div className="mt-5">
                            <div className="editor">
                              <ReactQuill
                                value={values.metaTitle}
                                onChange={(value) => {
                                  setFieldValue("metaTitle", value);
                                }}
                                className="h-35 rounded-lg"
                                theme="snow"
                                modules={{
                                  toolbar: [
                                    [
                                      { header: "1" },
                                      { header: "2" },
                                      { font: [] },
                                    ],
                                    [{ list: "ordered" }, { list: "bullet" }],
                                    ["bold", "italic", "underline"],
                                    ["link", "image"],
                                    ["clean"],
                                  ],
                                }}
                                formats={[
                                  "header",
                                  "font",
                                  "list",
                                  "bullet",
                                  "bold",
                                  "italic",
                                  "underline",
                                  "link",
                                  "image",
                                ]}
                              />
                            </div>
                            {/* <div className="mt-4">
        <h3 className="text-lg font-semibold mb-2">Content Preview:</h3>
        <div
          className="border border-gray-300 p-4 rounded-lg"
          dangerouslySetInnerHTML={{ __html: editorContent }}
        ></div>
      </div>
    </div> */}
                          </div>
                        </div>
                      )}
                      {activeTab === "description" && (
                        <div
                          id="description"
                          role="tabpanel"
                          aria-labelledby="description-tab"
                          className="tab-pane active p-5"
                        >
                          <Field
                            as="textarea"
                            id="description"
                            name="description"
                            value={values.description}
                            placeholder="Write your text"
                            className={`w-full text-sm border-slate-200 shadow-sm rounded-md p-2 placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary 
                      `}
                            style={{ height: "100px" }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-12 lg:col-span-4">
                <div className="intro-y box p-5">
                  <div className="mt-3">
                    <label
                      data-tw-merge=""
                      for="post-form-2"
                      className="inline-block font-space-grotesk mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right"
                    >
                      Read Time
                    </label>

                    <Field
                      name="readTime"
                      type="text"
                      placeholder="Enter Time (eg. 2 minutes 30 seconds)"
                      className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                    />
                    <ErrorMessage
                      name="readTime"
                      component="div"
                      className="text-red-500 mt-2"
                    />

                    <div></div>
                  </div>

                  <div className="mt-3">
                    <label className="">Post Date</label>
                    <DatePicker
                      placeholderText="Select Date"
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        setFieldValue("selectedDate", date);
                      }}
                      dateFormat="yyyy-MM-dd"
                      className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                    />

                    <ErrorMessage
                      name="selectedDate"
                      component="div"
                      className="text-red-500 mt-2"
                    />
                  </div>
                  <div className="mt-3">
                    <label className="inline-block mb-2">Select Time</label>
                    <DatePicker
                      placeholderText="Select Time"
                      selected={selectedTime}
                      onChange={(time) => {
                        setSelectedTime(time);
                        setFieldValue("selectedTime", time);
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                    />
                    <ErrorMessage
                      name="selectedTime"
                      component="div"
                      className="text-red-500 mt-2"
                    />
                  </div>

                  <div className="mt-3">
                    <label
                      data-tw-merge=""
                      for="post-form-3"
                      className="inline-block font-space-grotesk mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right"
                    >
                      Parent Categories
                    </label>
                    <Select
                      id="tag-select"
                      options={parentCategories}
                      onChange={handleChange}
                      classNamePrefix="tag-select"
                      className="w-full"
                    />
                  </div>

                  <div className="mt-3">
                    <label
                      data-tw-merge=""
                      for="post-form-3"
                      className="inline-block font-space-grotesk mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right"
                    >
                      Categories
                    </label>
                    <Select
                      id="tag-select"
                      isMulti
                      options={categories}
                      onChange={handleChildChange}
                      classNamePrefix="tag-select"
                      className="w-full"
                    />
                  </div>
                  <div className="mt-3">
                    <label
                      data-tw-merge=""
                      for="post-form-4"
                      className="inline-block font-space-grotesk mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right"
                    >
                      Tags
                    </label>
                    <Select
                      value={selectedOption}
                      onChange={handleTagChange}
                      options={options}
                      placeholder="Select an option"
                      isSearchable
                      isMulti
                      classNamePrefix="tag-select"
                      className="w-full"
                    />
                  </div>
                  <div
                    data-tw-merge=""
                    className="flex mt-3 flex-col items-start"
                  >
                    <label
                      data-tw-merge=""
                      for="post-form-5"
                      className="cursor-pointer mb-2 ml-0 font-space-grotesk"
                    >
                      Published
                    </label>

                    <input
                      type="checkbox"
                      id="post-form-5"
                      checked={isPublished}
                      onChange={handleCheckboxChange}
                      className="transition-all duration-100 ease-in-out shadow-sm border-slate-200 cursor-pointer focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-20 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&[type='radio']]:checked:bg-primary [&[type='radio']]:checked:border-primary [&[type='radio']]:checked:border-opacity-10 [&[type='checkbox']]:checked:bg-primary [&[type='checkbox']]:checked:border-primary [&[type='checkbox']]:checked:border-opacity-10 [&:disabled:not(:checked)]:bg-slate-100 [&:disabled:not(:checked)]:cursor-not-allowed [&:disabled:not(:checked)]:dark:bg-darkmode-800/50 [&:disabled:checked]:opacity-70 [&:disabled:checked]:cursor-not-allowed [&:disabled:checked]:dark:bg-darkmode-800/50 w-[38px] h-[24px] p-px rounded-full relative before:w-[20px] before:h-[20px] before:shadow-[1px_1px_3px_rgba(0,0,0,0.25)] before:transition-[margin-left] before:duration-200 before:ease-in-out before:absolute before:inset-y-0 before:my-auto before:rounded-full before:dark:bg-darkmode-600 checked:bg-primary checked:border-primary checked:bg-none before:checked:ml-[14px] before:checked:bg-white"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PostPage;
