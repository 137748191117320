import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import toast from "react-hot-toast";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-quill/dist/quill.snow.css";
import "tailwindcss/tailwind.css";
import { useDispatch } from "react-redux";
import Loader from "../../shared/Loader";
import {
  addAudioToLead,
  addLeadLogs,
  createPaymentLink,
  getBDAContactById,
} from "../../actions/bdaContact/bdaContact";
import { useParams } from "react-router-dom";

const ParticularContactLead = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isNextCall, setIsNextCall] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [audio, setAudio] = useState(null);
  const [isAudioModal, setIsAudioModal] = useState(false);
  const [leadLogId, setLeadLogId] = useState("");

  // for particular lead
  const [audioFile, setAudioFile] = useState(null);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenAudio = (id) => {
    setLeadLogId(id); // Store the id
    setIsAudioModal(true);
  };
  const handleCloseAudio = () => {
    setIsAudioModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await dispatch(getBDAContactById(id));
        if (res.success) {
          setRecords([res.data]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error(error?.response?.data?.message || "Something went wrong!");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, id]);

  const handleCheckboxChange = (event) => {
    setIsNextCall(event.target.checked);
  };

  const validationPaymentSchema = Yup.object({
    amount: Yup.number()
      .required("Amount is required")
      .min(1, "Amount must be greater than zero"),
  });

  const validationSchema = Yup.object().shape({
    leadCategory: Yup.object()
      .nullable()
      .required("Please select a lead category"),
    callStatus: Yup.object().nullable().required("Please select a call status"),
    legalDomain: Yup.object().nullable().required("Please select a option"),
    // audio: Yup.mixed().test(
    //   "fileType",
    //   "Only audio files are allowed",
    //   (value) => {
    //     // Allow null/undefined (not required) but validate if a file is present
    //     return !value || (value && value.type.startsWith("audio/"));
    //   }
    // ),
  });

  const validationAudio = Yup.object().shape({
    audioFile: Yup.mixed().test(
      "fileType",
      "Only audio files are allowed",
      (value) => {
        // Allow null/undefined (not required) but validate if a file is present
        return !value || (value && value.type.startsWith("audio/"));
      }
    ),
  });
  const options = [
    { value: "hotLead", label: "Hot Lead" },
    { value: "warmLead", label: "Warm Lead" },
    { value: "coldLead", label: "Cold Lead" },
    { value: "newLead", label: "New Lead" },
  ];

  const optionsCallStatus = [
    { value: "closed", label: "Closed" },
    { value: "newLead", label: "New Lead" },
    { value: "converted", label: "Converted" },
    { value: "interested", label: "Interested" },
    { value: "doNotContact", label: "Do Not Contact" },
    { value: "followUpScheduled", label: "Follow-Up Scheduled" },
  ];

  const legalDomainOptions = [
    { value: "civil", label: "Civil" },
    { value: "criminal", label: "Criminal" },
    { value: "matrimonialDispute", label: "Matrimonial Dispute" },
    { value: "financialIrregularities", label: "Financial Irregularities" },
  ];

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loader />
      </div>
    );
  }

  const handleSubmit = async (values, { resetForm }) => {
    console.log(values);
    setLoading(true);

    let gmtDateTime = null; // Initialize utcDateTime to null

    if (startDate && selectedTime) {
      // Combine date and time into a single Date object
      const combinedDateTime = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        selectedTime.getHours(),
        selectedTime.getMinutes()
      );

      const gmtOffset = combinedDateTime.getTimezoneOffset() * 60000; // Offset in milliseconds
      gmtDateTime = new Date(combinedDateTime.getTime() - gmtOffset)
        .toISOString()
        .replace("Z", "");
    }

    console.log(gmtDateTime);
    try {
      // const payload = {
      //   callStatus: values.callStatus.value,
      //   leadCategory: values.leadCategory.value,
      //   legalDomain: values.legalDomain.value,
      //   isNextCall,
      //   cSLeadId: id,
      //   ...(gmtDateTime && { nextCallTime: gmtDateTime }),
      //   ...(values.comment &&
      //     values.comment.trim() && { comment: values.comment }), // Only include if comment is not empty
      // };
      const formData = new FormData();
      formData.append("callStatus", values.callStatus.value);
      formData.append("leadCategory", values.leadCategory.value);
      formData.append("legalDomain", values.legalDomain.value);
      formData.append("isNextCall", isNextCall);
      formData.append("cSLeadId", id);

      if (gmtDateTime) {
        formData.append("nextCallTime", gmtDateTime);
      }
      if (values.comment && values.comment.trim() !== "") {
        formData.append("comment", values.comment);
      }
      if (audio) {
        formData.append("audio", audio);
      }

      const res = await dispatch(addLeadLogs(formData));
      if (res.success) {
        toast.success(res.message);
        setSelectedTime(null);
        setStartDate(null);
        setIsNextCall(false);
        resetForm();
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleAudio = async (values, { resetForm }) => {
    console.log(values);
    setLoading(true);

    try {
      const formData = new FormData();

      formData.append("audio", audioFile);
      formData.append("id", leadLogId);

      const res = await dispatch(addAudioToLead(formData));
      if (res.success) {
        toast.success(res.message);
        setAudioFile(null);
        setIsAudioModal(false);
        resetForm();
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const formatCapitalize = (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between camelCase or PascalCase
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  const handlePayment = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const payload = {
        leadId: id,
        amount: values.amount.toString(),
      };
      const res = await dispatch(createPaymentLink(payload));
      if (res.success) {
        toast.success(res.message);
        setIsModalOpen(false);
        resetForm();
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="flex justify-between">
        {" "}
        <h2 className="intro-y text-lg font-medium font-space-grotesk">
          User Lead
        </h2>
        <div className=" w-full sm:ml-auto sm:mt-0 sm:w-auto md:ml-5">
          <button
            data-tw-merge=""
            className="transition duration-200 border inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary mr-2 shadow-md"
            onClick={handleOpenModal}
          >
            Create Payment Link
          </button>
        </div>
      </div>

      <div className="mt-5 grid gap-6">
        <div className="intro-y col-span-12 overflow-x-auto max-w-full custom-scroll mt-5">
          <table
            data-tw-merge=""
            className="w-full text-left -mt-2 border-separate border-spacing-y-[10px]"
          >
            <thead data-tw-merge="" className="">
              <tr data-tw-merge="" className="">
                <th
                  data-tw-merge=""
                  className="font-medium font-base font-space-grotesk  px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center"
                >
                  NAME
                </th>

                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                  EMAIL
                </th>
                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                  CONTACT NUMBER
                </th>
                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                  DATA RESOURCE
                </th>
                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                  SLUG
                </th>

                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                  TIME STAMP
                </th>
                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                  MESSAGE
                </th>
                <th
                  data-tw-merge=""
                  className="font-medium font-space-groteskfont-base  px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center"
                >
                  ADD MANUAL
                </th>
              </tr>
            </thead>
            <tbody>
              {records.map((record) => (
                <React.Fragment key={record.id}>
                  {/* Main Contact Info Row */}
                  <tr data-tw-merge="" className="intro-x">
                    <td className="px-2 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm">
                      {record.name ? record.name : "-"}
                    </td>
                    <td className="px-2 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm">
                      {record.email ? record.email : "-"}
                    </td>
                    <td className="px-2 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm">
                      {record.mobileNumber ? record.mobileNumber : "-"}
                    </td>
                    <td className="px-2 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm">
                      {record.data_from_page === "Others"
                        ? "-"
                        : record.data_from_page}
                    </td>
                    <td className="px-2 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm">
                      {record.slug}
                    </td>
                    <td className="px-2 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm">
                      {new Date(record.createdAt).toLocaleString()}
                    </td>
                    <td
                      className="px-2 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm"
                      title={record?.message || "No message"}
                    >
                      {record?.message && record?.message.length > 30
                        ? `${record?.message.substring(0, 30)}...`
                        : record?.message || "No message"}
                    </td>
                    <td
                    data-tw-merge=""
                    className="px-2 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005]  first:border-l  last:border-r dark:bg-darkmode-600 font-sm"
                  >
                    {record.addedManually ? "Yes" : "No"}
                  </td>
                  </tr>

                  {/* Lead Logs Table */}
                  {record?.leadLogs?.length > 0 ? (
                    <>
                      <h2 className="intro-y text-lg font-medium font-space-grotesk w-36 mt-5">
                        Lead Logs
                      </h2>
                      <tr>
                        <td colSpan="7">
                          <table className="w-full">
                            <thead>
                              <tr>
                                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                                  Lead Category
                                </th>
                                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                                  Legal Domain
                                </th>
                                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                                  Call Status
                                </th>
                                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                                  Next Call Time
                                </th>
                                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                                  Audio
                                </th>
                                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                                  Comment
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {record.leadLogs.map((log) => (
                                <tr key={log.id}>
                                  <td className="px-2 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm">
                                    {formatCapitalize(log.leadCategory)}
                                  </td>
                                  <td className=" contact-msg px-5 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm">
                                    {formatCapitalize(log.legalDomain)}
                                  </td>
                                  <td className=" contact-msg px-5 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm">
                                    {formatCapitalize(log.callStatus)}
                                  </td>
                                  <td className=" contact-msg px-5 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm">
                                    {log.nextCallTime === null
                                      ? "-"
                                      : new Date(
                                          log.nextCallTime
                                        ).toLocaleString()}
                                  </td>
                                  <td className=" contact-msg px-5 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm">
                                    {log.audio_url ? (
                                      <a
                                        href={log.audio_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-primary underline font-space-grotesk"
                                      >
                                        Play Audio
                                      </a>
                                    ) : (
                                      <button
                                        data-tw-merge=""
                                        className="transition duration-200 border inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary mr-2 shadow-md"
                                        onClick={() => handleOpenAudio(log.id)}
                                      >
                                        Upload Audio
                                      </button>
                                    )}
                                  </td>
                                  <td className=" contact-msg px-5 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm">
                                    {log.comment || "-"}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </>
                  ) : null}
                  {record?.payments?.length > 0 ? (
                    <>
                      <h2 className="intro-y text-lg font-medium font-space-grotesk w-36 mt-5">
                        Payment Logs
                      </h2>
                      <tr>
                        <td colSpan="7">
                          <table className="w-full">
                            <thead>
                              <tr>
                                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                                  Link
                                </th>
                                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                                  Amount
                                </th>
                                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                                  Currency
                                </th>
                                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                                  Status
                                </th>
                                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                                  Created At
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {record.payments.map((log) => (
                                <tr key={log.id}>
                                  <td className="px-2 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm">
                                    {log.link}
                                  </td>
                                  <td className=" contact-msg px-5 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm">
                                    {log.amount}
                                  </td>
                                  <td className=" contact-msg px-5 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm">
                                    {log.currencyUnit}
                                  </td>
                                  <td className=" contact-msg px-5 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm">
                                    {log.status}
                                  </td>
                                  <td className=" contact-msg px-5 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm">
                                    {new Date(log.createdAt).toLocaleString()}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </>
                  ) : null}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white dark:bg-gray-900 p-6 rounded-md shadow-lg w-96">
            <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">
              Enter Amount
            </h2>
            <Formik
              initialValues={{ amount: "" }}
              validationSchema={validationPaymentSchema}
              onSubmit={handlePayment}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="mb-4">
                    <Field
                      type="number"
                      name="amount"
                      placeholder="Amount"
                      className="w-full border border-gray-300 dark:border-gray-700 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-primary dark:bg-gray-800 dark:text-gray-200"
                    />
                    <ErrorMessage
                      name="amount"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>
                  <div className="flex justify-end space-x-2">
                    <button
                      type="button"
                      onClick={handleCloseModal}
                      className="py-2 px-4 bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-md"
                    >
                      Cancel
                    </button>
                    <button
                      disabled={isLoading}
                      type="submit"
                      className={`py-2 px-4 rounded-md ${
                        isLoading
                          ? "bg-gray-400 cursor-not-allowed"
                          : "bg-primary text-white"
                      }`}
                    >
                      {loading ? "Processing..." : "Submit"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}

      {isAudioModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white dark:bg-gray-900 p-6 rounded-md shadow-lg w-96">
            <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">
              Add Audio
            </h2>
            <Formik
              initialValues={{ audio: null }}
              validationSchema={validationAudio}
              onSubmit={handleAudio}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="mb-4">
                    <Field name="audioFile">
                      {({
                        field,
                        form: { setFieldValue, errors, touched },
                      }) => (
                        <div>
                          <label
                            htmlFor="audio"
                            className="inline-block font-space-grotesk mb-2"
                          >
                            Upload Audio File
                          </label>
                          <input
                            id="audioFile"
                            type="file"
                            accept="audio/*" // Accept only audio files
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              if (file && file.type.startsWith("audio/")) {
                                setFieldValue("audioFile", file); // Update Formik state with the file
                                setAudioFile(file);
                              } else {
                                setFieldValue("audioFile", null); // Clear invalid file
                                alert("Please upload a valid audio file.");
                              }
                            }}
                            className={`w-full text-sm border-slate-200 shadow-sm rounded-md p-2 placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary 
          ${errors.audio && touched.audio ? "border-red-500" : "border-gray"}
        `}
                          />
                          <ErrorMessage
                            name="audioFile"
                            component="div"
                            className="text-red-500 mt-1 text-sm"
                          />
                          {field.value &&
                            field.value.type.startsWith("audio/") && (
                              <audio controls className="mt-3 w-full">
                                <source
                                  src={URL.createObjectURL(field.value)}
                                  type={field.value.type}
                                />
                                Your browser does not support the audio element.
                              </audio>
                            )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="flex justify-end space-x-2">
                    <button
                      type="button"
                      onClick={handleCloseAudio}
                      className="py-2 px-4 bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-md"
                    >
                      Cancel
                    </button>
                    <button
                      disabled={isLoading}
                      type="submit"
                      className={`py-2 px-4 rounded-md ${
                        isLoading
                          ? "bg-gray-400 cursor-not-allowed"
                          : "bg-primary text-white"
                      }`}
                    >
                      {loading ? "Processing..." : "Submit"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
      <Formik
        initialValues={{
          leadCategory: null,
          callStatus: null,
          comment: "",
          audio: null,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form>
            {/* <h2 className="intro-y mt-10 text-lg font-medium font-space-grotesk">
              Lead Logs
            </h2> */}
            <div className="mt-5 grid grid-cols-12 gap-6">
              <div className="intro-y col-span-12">
                <div className="intro-y box p-5">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <div>
                      <label
                        htmlFor="post-form-3"
                        className="inline-block font-space-grotesk mb-2"
                      >
                        Lead Categories
                      </label>
                      <Field name="leadCategory">
                        {({ field }) => (
                          <Select
                            id="tag-select"
                            options={options}
                            onChange={(option) =>
                              setFieldValue("leadCategory", option)
                            }
                            classNamePrefix="tag-select"
                            className={`w-full ${
                              errors.leadCategory && touched.leadCategory
                                ? "border-red-500"
                                : ""
                            }`}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="leadCategory"
                        component="div"
                        className="text-red-500 mt-1 text-sm"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="post-form-3"
                        className="inline-block font-space-grotesk mb-2"
                      >
                        Call Status
                      </label>
                      <Field name="callStatus">
                        {({ field }) => (
                          <Select
                            id="tag-select"
                            options={optionsCallStatus}
                            onChange={(option) =>
                              setFieldValue("callStatus", option)
                            }
                            classNamePrefix="tag-select"
                            className={`w-full ${
                              errors.callStatus && touched.callStatus
                                ? "border-red-500"
                                : ""
                            }`}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="callStatus"
                        component="div"
                        className="text-red-500 mt-1 text-sm"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="post-form-3"
                        className="inline-block font-space-grotesk mb-2"
                      >
                        Legal Domain
                      </label>
                      <Field name="legalDomain">
                        {({ field }) => (
                          <Select
                            id="tag-select"
                            options={legalDomainOptions}
                            onChange={(option) =>
                              setFieldValue("legalDomain", option)
                            }
                            classNamePrefix="tag-select"
                            className={`w-full ${
                              errors.legalDomain && touched.legalDomain
                                ? "border-red-500"
                                : ""
                            }`}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="legalDomain"
                        component="div"
                        className="text-red-500 mt-1 text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="comment" className="inline-block mb-2">
                        Comment
                      </label>

                      <Field
                        as="textarea"
                        id="description"
                        name="description"
                        onChange={(e) => {
                          const { value } = e.target;
                          setFieldValue("comment", value);
                        }}
                        placeholder="Enter Comment"
                        className={`w-full text-sm border-slate-200 shadow-sm rounded-md p-2 placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary 
                      `}
                        style={{ height: "100px" }}
                      />
                    </div>

                    {/* <Field name="audio">
                      {({ field }) => (
                        <div>
                          <label
                            htmlFor="audio"
                            className="inline-block font-space-grotesk mb-2"
                          >
                            Upload Audio File
                          </label>
                          <input
                            id="audio"
                            type="file"
                            accept="audio/*"
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              setFieldValue("audio", file); // Update Formik state with the file
                              setAudio(file);
                            }}
                            className={`w-full text-sm border-slate-200 shadow-sm rounded-md p-2 placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary 
                      ${
                        errors.audio && touched.audio
                          ? "border-red-500"
                          : "border-gray"
                      }`}
                          />
                          <ErrorMessage
                            name="audio"
                            component="div"
                            className="text-red-500 mt-1 text-sm"
                          />
                          {field.value && (
                            <audio controls className="mt-3 w-full">
                              <source
                                src={URL.createObjectURL(field.value)}
                                type={field.value.type}
                              />
                              Your browser does not support the audio element.
                            </audio>
                          )}
                        </div>
                      )}
                    </Field> */}
                    <Field name="audio">
                      {({
                        field,
                        form: { setFieldValue, errors, touched },
                      }) => (
                        <div>
                          <label
                            htmlFor="audio"
                            className="inline-block font-space-grotesk mb-2"
                          >
                            Upload Audio File
                          </label>
                          <input
                            id="audio"
                            type="file"
                            accept="audio/*" // Accept only audio files
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              if (file && file.type.startsWith("audio/")) {
                                setFieldValue("audio", file); // Update Formik state with the file
                                setAudio(file);
                              } else {
                                setFieldValue("audio", null); // Clear invalid file
                                alert("Please upload a valid audio file.");
                              }
                            }}
                            className={`w-full text-sm border border-slate-200  rounded-md p-2 placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary 
                      `}
                          />
                          <ErrorMessage
                            name="audio"
                            component="div"
                            className="text-red-500 mt-1 text-sm"
                          />
                          {field.value &&
                            field.value.type.startsWith("audio/") && (
                              <audio controls className="mt-3 w-full">
                                <source
                                  src={URL.createObjectURL(field.value)}
                                  type={field.value.type}
                                />
                                Your browser does not support the audio element.
                              </audio>
                            )}
                        </div>
                      )}
                    </Field>

                    {isNextCall && (
                      <div>
                        <label
                          data-tw-merge=""
                          for="post-form-2"
                          className="inline-block font-space-grotesk mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right"
                        >
                          Post Date
                        </label>

                        <div>
                          <DatePicker
                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                            placeholderText="Select Date"
                            selected={startDate}
                            onChange={(date) => {
                              setStartDate(date);
                              setFieldValue("selectedDate", date); // Save as UTC format
                            }}
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                        <div className="mt-3">
                          <label className="inline-block mb-2">
                            Select Time
                          </label>

                          <DatePicker
                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                            placeholderText="Select Time"
                            selected={selectedTime}
                            onChange={(time) => {
                              setSelectedTime(time);
                              setFieldValue("selectedTime", time);
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="HH:mm"
                          />
                          <ErrorMessage
                            name="selectedTime"
                            component="div"
                            className="text-red-500 mt-2"
                          />
                        </div>
                      </div>
                    )}

                    <div
                      data-tw-merge=""
                      className="flex mt-3 flex-col items-start"
                    >
                      <label
                        data-tw-merge=""
                        for="post-form-5"
                        className="cursor-pointer mb-2 ml-0 font-space-grotesk"
                      >
                        Next Call
                      </label>

                      <input
                        type="checkbox"
                        id="post-form-5"
                        checked={isNextCall}
                        onChange={handleCheckboxChange}
                        className="transition-all duration-100 ease-in-out shadow-sm border-slate-200 cursor-pointer focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-20 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&[type='radio']]:checked:bg-primary [&[type='radio']]:checked:border-primary [&[type='radio']]:checked:border-opacity-10 [&[type='checkbox']]:checked:bg-primary [&[type='checkbox']]:checked:border-primary [&[type='checkbox']]:checked:border-opacity-10 [&:disabled:not(:checked)]:bg-slate-100 [&:disabled:not(:checked)]:cursor-not-allowed [&:disabled:not(:checked)]:dark:bg-darkmode-800/50 [&:disabled:checked]:opacity-70 [&:disabled:checked]:cursor-not-allowed [&:disabled:checked]:dark:bg-darkmode-800/50 w-[38px] h-[24px] p-px rounded-full relative before:w-[20px] before:h-[20px] before:shadow-[1px_1px_3px_rgba(0,0,0,0.25)] before:transition-[margin-left] before:duration-200 before:ease-in-out before:absolute before:inset-y-0 before:my-auto before:rounded-full before:dark:bg-darkmode-600 checked:bg-primary checked:border-primary checked:bg-none before:checked:ml-[14px] before:checked:bg-white"
                      />
                    </div>
                  </div>

                  <div className="mt-5 text-right">
                    <button
                      disabled={loading}
                      type="submit"
                      className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 bg-primary border-primary text-white w-24"
                    >
                      {loading ? "Saving..." : "Save"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      {/* <div className="mt-5 grid gap-6">
          <div className="intro-y col-span-12 overflow-x-auto max-w-full custom-scroll">
            <table className="w-full text-left -mt-2 border-separate border-spacing-y-[10px]">
              <thead>
                <tr>
                  {["ID", "NEXT CALL", "CREATED AT", "UPDATED AT"].map(
                    (header) => (
                      <th
                        key={header}
                        className="font-medium font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center"
                      >
                        {header}
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {leadLog.map((log, index) => (
                  <tr key={log.id} className="intro-x">
                    <td className="px-2 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm">
                      {index + 1}
                    </td>
                    <td className="px-2 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm">
                      {log.isNextCall ? "Yes" : "No"}
                    </td>
                    <td className="px-2 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm">
                      {new Date(log.createdAt).toLocaleString()}
                    </td>
                    <td className="px-2 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm">
                      {new Date(log.updatedAt).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
   */}
    </>
  );
};

export default ParticularContactLead;
