// import React from "react";
// import {
//   ChevronsLeft,
//   ChevronLeft,
//   ChevronRight,
//   ChevronsRight,
// } from "lucide-react";

// const Pagination = ({
//   currentPage,
//   totalPages,
//   onPageChange,
//   itemsPerPage,
//   onItemsPerPageChange,
// }) => {
//   const handlePageChange = (newPage) => {
//     if (newPage >= 1 && newPage <= totalPages) {
//       onPageChange(newPage);
//     }
//   };

//   return (
//     <>
//       <div className="intro-y col-span-12 flex flex-wrap items-center sm:flex-row sm:flex-nowrap">
//         <nav className="w-full sm:mr-auto sm:w-auto">
//           <ul className="flex w-full mr-0 sm:mr-auto sm:w-auto">
//             <li className="flex-1 sm:flex-initial">
//               <a
//                 onClick={() => handlePageChange(1)}
//                 className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
//               >
//                 <ChevronsLeft className="stroke-1.5 h-4 w-4" />
//               </a>
//             </li>
//             <li className="flex-1 sm:flex-initial">
//               <a
//                 onClick={() => handlePageChange(currentPage - 1)}
//                 className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
//               >
//                 <ChevronLeft className="stroke-1.5 h-4 w-4" />
//               </a>
//             </li>
//             {/* Page numbers (simplified) */}
//             {[...Array(totalPages)].map((_, index) => (
//               <li key={index} className="flex-1 sm:flex-initial">
//                 <a
//                   onClick={() => handlePageChange(index + 1)}
//                   className={`  transition font-space-grotesk  duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3 !box dark:bg-darkmode-400 ${
//                     index + 1 === currentPage ? "!box dark:bg-darkmode-400" : ""
//                   }`}
//                 >
//                   {index + 1}
//                 </a>
//               </li>
//             ))}
//             <li className="flex-1 sm:flex-initial">
//               <a
//                 onClick={() => handlePageChange(currentPage + 1)}
//                 className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
//               >
//                 <ChevronRight className="stroke-1.5 h-4 w-4" />
//               </a>
//             </li>
//             <li className="flex-1 sm:flex-initial">
//               <a
//                 onClick={() => handlePageChange(totalPages)}
//                 className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
//               >
//                 <ChevronsRight className="stroke-1.5 h-4 w-4" />
//               </a>
//             </li>
//           </ul>
//         </nav>
//         <select
//           value={itemsPerPage}
//           onChange={(e) => onItemsPerPageChange(Number(e.target.value))}
//           className="disabled:bg-slate-100 font-space-grotesk  disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 !box mt-3 w-20 sm:mt-0"
//         >
//           <option value={10}>10</option>
//           <option value={25}>25</option>
//           <option value={50}>50</option>
//           <option value={100}>100</option>
//         </select>
//       </div>
//     </>
//   );
// };

// export default Pagination;

import React from "react";
import {
  ChevronsLeft,
  ChevronLeft,
  ChevronRight,
  ChevronsRight,
} from "lucide-react";

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  onItemsPerPageChange,
}) => {
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const visiblePages = 3; // Number of visible pages
  
    if (totalPages <= visiblePages) {
      // If total pages are less than or equal to visiblePages, show all pages.
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // If the current page is the first page
      if (currentPage === 1) {
        pageNumbers.push(1, 2, 3);
      } 
      // If the current page is the last page
      else if (currentPage === totalPages) {
        pageNumbers.push(totalPages - 2, totalPages - 1, totalPages);
      } 
      // For all other cases
      else {
        pageNumbers.push(currentPage - 1, currentPage, currentPage + 1);
      }
    }
  
    return pageNumbers;
  };
  
  return (
    <div className="intro-y col-span-12 flex flex-wrap items-center sm:flex-row sm:flex-nowrap">
      <nav className="w-full sm:mr-auto sm:w-auto">
        <ul className="flex w-full mr-0 sm:mr-auto sm:w-auto">
          {/* First Page */}
          <li className="flex-1 sm:flex-initial">
            <a
              onClick={() => handlePageChange(1)}
              className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
            >
              <ChevronsLeft className="stroke-1.5 h-4 w-4" />
            </a>
          </li>

          {/* Previous Page */}
          <li className="flex-1 sm:flex-initial">
            <a
              onClick={() => handlePageChange(currentPage - 1)}
              className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
            >
              <ChevronLeft className="stroke-1.5 h-4 w-4" />
            </a>
          </li>

          {/* Dynamic Page Numbers */}
          {getPageNumbers().map((page, index) => (
            <li key={index} className="flex-1 sm:flex-initial">
              {page === "..." ? (
                <span className="pagination-ellipsis">...</span>
              ) : (
                <a
                  onClick={() => handlePageChange(page)}
                  // className={`pagination-btn ${
                  //   page === currentPage ? "active" : ""
                  // }`}
                  className={`  transition font-space-grotesk  duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3  dark:bg-darkmode-400 ${
                    page === currentPage ? "bg-primary text-white" : "!box"
                  }`}
                >
                  {page}
                </a>
              )}
            </li>
          ))}

          {/* Next Page */}
          <li className="flex-1 sm:flex-initial">
            <a
              onClick={() => handlePageChange(currentPage + 1)}
              className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
            >
              <ChevronRight className="stroke-1.5 h-4 w-4" />
            </a>
          </li>

          {/* Last Page */}
          <li className="flex-1 sm:flex-initial">
            <a
              onClick={() => handlePageChange(totalPages)}
              className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
            >
              <ChevronsRight className="stroke-1.5 h-4 w-4" />
            </a>
          </li>
        </ul>
      </nav>

      <span className="mr-4 text-sm font-medium text-gray-600 font-space-grotesk ">
        Page {currentPage} of {totalPages}
      </span>
      {/* Items Per Page Dropdown */}
      <select
        value={itemsPerPage}
        onChange={(e) => onItemsPerPageChange(Number(e.target.value))}
        className="disabled:bg-slate-100 font-space-grotesk  disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 !box mt-3 w-20 sm:mt-0"
      >
        <option value={10}>10</option>
        <option value={25}>25</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </select>
    </div>
  );
};

export default Pagination;
