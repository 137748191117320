// import * as actionTypes from '../../constants/actionTypes';

// const storedProfile = localStorage.getItem('profile');
// const initialProfile = storedProfile ? JSON.parse(storedProfile) : null;

// const initialState = {
//     login: initialProfile,
//     isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
//     state: 'idle',
//     error: null,
// };

// const authReducer = (state = initialState, action) => {
//     switch (action.type) {
//         case actionTypes.LOGIN:
//             localStorage.setItem('profile', JSON.stringify(action.payload));
//             localStorage.setItem('isAuthenticated', 'true');
//             return {
//                 ...state,
//                 login: action.payload,
//                 isAuthenticated: true,
//             };
//         case actionTypes.LOGOUT_BLOGGER:
//             case actionTypes.LOGOUT_BDA:
//             localStorage.removeItem('profile');
//             localStorage.removeItem('isAuthenticated');
//             return {
//                 ...state,
//                 login: null,
//                 isAuthenticated: false,
//             };
//         default:
//             return state;
//     }
// };

// export default authReducer;

import * as actionTypes from '../../constants/actionTypes';

const storedAuthToken = localStorage.getItem('EMPLOYEE_LW_AUTHTOKEN');
const initialState = {
    authToken: storedAuthToken || null,
    isAuthenticated: !!storedAuthToken, // Checks if there's an authToken
    state: 'idle',
    error: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN:
            const { authToken } = action.payload; // Extracting authToken from payload
            localStorage.setItem('EMPLOYEE_LW_AUTHTOKEN', authToken);
            return {
                ...state,
                authToken,
                isAuthenticated: true,
            };
        case actionTypes.LOGOUT_BLOGGER:
        case actionTypes.LOGOUT_BDA:
            localStorage.removeItem('EMPLOYEE_LW_AUTHTOKEN');
            return {
                ...state,
                authToken: null,
                isAuthenticated: false,
            };
        default:
            return state;
    }
};

export default authReducer;
