import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import LayoutLoader from "./components/layoutLoader/LayoutLoader";
import ProtectedRoute from "./components/protectedRoute/ProtectedRoute";
import "./App.css";
import EditParentCategory from "./components/master/parentCategory/EditParentCategory";
import TagManagement from "./components/master/tag/TagManagement";
import CategoryTab from "./components/master/category/CategoryTab";
import ParentCategoryTab from "./components/master/parentCategory/ParentCategoryTab";
import Dashboard from "./components/dashboard/Dashboard";
import EditCategory from "./components/master/category/EditCategory";
import EditTag from "./components/master/tag/EditTag";
import PostTab from "./components/post/PostTab";
import EditBlog from "./components/post/EditBlog";
import BDALayout from "./components/BDALayout/BDALayout";
import BDADashboard from "./components/BDADashboard/BDADashboard";
import BDAContactLead from "./components/BDAContactLead/BDAContactLead";
import ParticularContactLead from "./components/BDAContactLead/ParticularContactLead";
import AddManualLead from "./components/BDAContactLead/AddManualLead";
import BDAPetitionLead from "./components/BDAPetitionLead/BDAPetitionLead";
import ParticularPetitionLead from "./components/BDAPetitionLead/ParticularPetitionLead";

const Login = lazy(() => import("./components/login/Login"));
const BloggerLayout = lazy(() => import("./components/sidebar/Sidebar"));


function App() {
  return (
    <>
      <Router>
        <Suspense fallback={<LayoutLoader />}>
          <Routes>
            <Route path="/" element={<Login />} />

            <Route
              path="/blogger"
              element={
                <ProtectedRoute>
                  <BloggerLayout />
                </ProtectedRoute>
              }
            >
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="post" element={<PostTab />} />
              <Route path="parent-category" element={<ParentCategoryTab />} />
              <Route path="category" element={<CategoryTab />} />
              <Route path="tag" element={<TagManagement />} />
              <Route
                path="edit-parent-category/:slug"
                element={<EditParentCategory />}
              />
               <Route
                path="edit-category/:slug"
                element={<EditCategory />}
              />
               <Route
                path="edit-tag/:slug"
                element={<EditTag />}
              />
               <Route
                path="edit-blog/:slug"
                element={<EditBlog />}
              />
            </Route>


            <Route
              path="/BDA"
              element={
                <ProtectedRoute>
                  <BDALayout />
                </ProtectedRoute>
              }
            >
              <Route path="dashboard" element={<BDADashboard />} />
              <Route path="contact-lead" element={<BDAContactLead />} />
              <Route path="petition-lead" element={<BDAPetitionLead />} />
              <Route path="view-contact-lead/:id" element={<ParticularContactLead />} />
              <Route path="view-petition-lead/:id" element={<ParticularPetitionLead />} />
              <Route path="add-manual" element={<AddManualLead />} />
            </Route>
          </Routes>
          <Toaster position="bottom-center" />
        </Suspense>
      </Router>
    </>
  );
}

export default App;
