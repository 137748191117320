import * as api from "../../api";
import {BDA_PETITION_LEAD, BDA_PETITION_LEAD_BY_ID ,ADD_PETITION_LEAD_LOGS } from "../../constants/actionTypes";

export const getBDAPetition = (params) => async (dispatch) => {
  try {
    const { data } = await api.getBDAPetition(params);
    dispatch({ type: BDA_PETITION_LEAD, payload: data });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getBDAPetitionById = (id) => async (dispatch) => {
  try {
    const { data } = await api.getBDAPetitionById(id);
    dispatch({ type: BDA_PETITION_LEAD_BY_ID, payload: data });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
  
  export const addPetitionLeadLogs = (logsData) => async (dispatch) => {
    try {
      const response = await api.addPetitionLeadLogs(logsData);
      dispatch({ type: ADD_PETITION_LEAD_LOGS, payload: response.data });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
