import React, { useState, useEffect, useRef } from "react";
import { Search, Printer, FileText, SlidersHorizontal } from "lucide-react";
import toast from "react-hot-toast";
import { CheckCircle } from "lucide-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-quill/dist/quill.snow.css";
import "tailwindcss/tailwind.css";
import { useDispatch } from "react-redux";
import Loader from "../../shared/Loader";
import Pagination from "../pagination/Pagination";
import { getBDAContact } from "../../actions/bdaContact/bdaContact";
import { Link } from "react-router-dom";

const BDAContactLead = () => {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showRangePicker, setShowRangePicker] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [isMutual, setIsMutual] = useState(false);
  const [startDate, endDate] = dateRange;

  const handleDateChange = (update) => {
    setDateRange(update);

    // Check if both dates are selected
    if (update[0] !== null && update[1] !== null) {
      const formatStart = convertDateToISO(update[0]);
      const formatEnd = convertDateToISO(update[1]);
      setSelectedStartDate(formatStart);
      setSelectedEndDate(formatEnd);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close the dropdown
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen); // Toggle dropdown state
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const params = {
          page: currentPage,
          limit: itemsPerPage,
          date: selectedDate,
          startDate: selectedStartDate,
          endDate: selectedEndDate,
          isMutual,
        };
        const res = await dispatch(getBDAContact(params));
        if (res.success) {
          setRecords(res.data);
          setTotalPages(res.totalPage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error(error?.response?.data?.message || "Something went wrong!");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    dispatch,
    currentPage,
    itemsPerPage,
    selectedDate,
    selectedStartDate,
    selectedEndDate,
    isMutual,
  ]);

  // console.log(totalPages)

  const convertDateToISO = (dateString) => {
    // Create a new Date object from the input date string
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date");
    }

    // Extract year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");

    // Return formatted date string
    return `${year}-${month}-${day}`;
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        {" "}
        <Loader />
      </div>
    );
  }

  const adjustAndFormatTime = (createdAt) => {
    // Parse the input date string into a Date object
    const date = new Date(createdAt);

    // Adjust the time by adding 5 hours and 30 minutes (330 minutes)
    date.setMinutes(date.getMinutes() + 330);

    // Extract day, month, year, hours, minutes, and seconds
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    // Format date and time separately
    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return (
      <>
        <div>{formattedDate}</div>
        <div>{formattedTime}</div>
      </>
    );
  };

  function handleSearchClick() {
    if (searchText.trim() === "") {
      setRecords(records);
      return;
    }

    const filteredRecords = records.filter((item) => {
      const searchValue = searchText.toLowerCase();

      return (
        item?.name?.toLowerCase().includes(searchValue) ||
        item?.mobileNumber?.toString().includes(searchValue)
        // item.slug?.toLowerCase().includes(searchValue) ||
      );
    });

    // Update the state with the filtered records
    setRecords(filteredRecords);
  }

  // console.log(dateRange);
  return (
    <>
      <div className="flex justify-between">
        {" "}
        <h2 className="intro-y text-lg font-medium font-space-grotesk">
          Contact Data
        </h2>
        <div className=" w-full sm:ml-auto sm:mt-0 sm:w-auto md:ml-5">
          <Link
            to={`/BDA/add-manual`}
            className="transition duration-200 border inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary mr-2 shadow-md"
          >
            Add Manually
          </Link>
        </div>
      </div>
      <div class="mt-5 grid grid-cols-12 gap-6">
        <div class="intro-y col-span-12 mt-2 flex flex-wrap items-center sm:flex-nowrap">
          <div className="intro-y col-span-12 mt-2 flex flex-wrap items-center sm:flex-nowrap">
            <div
              data-tw-merge=""
              data-tw-placement="bottom-end"
              className="dropdown relative"
              ref={dropdownRef}
            >
              <button
                data-tw-merge=""
                data-tw-toggle="dropdown"
                aria-expanded={isOpen}
                onClick={toggleDropdown}
                className="transition duration-200 border shadow-sm mr-2 inline-flex items-center justify-center py-2 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed !box px-2"
              >
                <span className="flex h-5 w-5 items-center justify-center">
                  <SlidersHorizontal className="stroke-1.5 h-6 w-6" />
                </span>
              </button>

              <div
                data-transition=""
                data-selector=".show"
                className={`dropdown-menu absolute z-[9999] ${
                  isOpen ? "visible" : "hidden"
                }`}
              >
                <div
                  data-tw-merge=""
                  className="dropdown-content rounded-md border-transparent bg-white p-2 shadow-[0px_3px_10px_#00000017] dark:border-transparent dark:bg-darkmode-600 w-40"
                >
                  <button
                    className="cursor-pointer font-space-grotesk  flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item"
                    onClick={() => {
                      setShowDatePicker(true);
                      setShowRangePicker(false);
                      setIsOpen(false);
                    }}
                  >
                    <Printer className="stroke-1.5 mr-2 h-4 w-4" />
                    Select Date
                  </button>

                  <button
                    className="cursor-pointer font-space-grotesk  flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item"
                    onClick={() => {
                      setIsMutual(true);
                      setIsOpen(false);
                    }}
                  >
                    <Printer className="stroke-1.5 mr-2 h-4 w-4" />
                    Mutual
                  </button>

                  <a
                    className="cursor-pointer font-space-grotesk  flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item"
                    onClick={() => {
                      setShowRangePicker(true);
                      setShowDatePicker(false);
                      setIsOpen(false);
                    }}
                  >
                    <FileText className="stroke-1.5 mr-2 h-4 w-4" />
                    Date Range
                  </a>
                </div>
              </div>
            </div>

            {showDatePicker && !showRangePicker && (
              <div className="mt-3 w-full sm:ml-auto sm:mt-0 sm:w-auto md:ml-0">
                <div className="relative w-56 text-slate-500">
                  <DatePicker
                    id="date-picker"
                    selected={selectedDate}
                    placeholderText="Select Date"
                    onChange={(date) => {
                      const formattedDate = convertDateToISO(date);
                      setSelectedDate(formattedDate);
                      setShowDatePicker(false); // Hide DatePicker after selection
                    }}
                    dateFormat="yyyy-MM-dd"
                    className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80"
                  />
                </div>
              </div>
            )}

            {showRangePicker && !showDatePicker && (
              <div className="mt-3 w-full sm:ml-auto sm:mt-0 sm:w-auto md:ml-0">
                <div className="relative w-56 text-slate-500">
                  <DatePicker
                    selectsRange={true} // Enables range selection
                    startDate={startDate}
                    endDate={endDate}
                    onChange={handleDateChange}
                    isClearable={true} // Allow the user to clear the selection
                    placeholderText="Select date range"
                    className="border p-2 rounded-md"
                  />
                </div>
              </div>
            )}

            <div className="mt-3 w-full sm:ml-auto sm:mt-0 sm:w-auto md:ml-0">
              <div className="relative w-56 text-slate-500">
                <input
                  data-tw-merge=""
                  type="text"
                  placeholder="Search..."
                  className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 !box w-56 pr-10"
                  value={searchText}
                  onChange={handleSearchInputChange}
                />

                <Search
                  className="stroke-1.5 absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4"
                  onClick={handleSearchClick}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="mt-5 grid grid-cols-12 gap-6"> */}
        <div className="intro-y col-span-12 overflow-x-auto max-w-full custom-scroll mt-5">
          {/* <div className="intro-y col-span-12 overflow-auto lg:overflow-visible" > */}

          <table
            data-tw-merge=""
            // className="w-full text-left -mt-2 border-separate border-spacing"
            className="w-full text-left border-separate border-spacing bg-white rounded-[10px] shadow-md overflow-hidden"
          >
            <thead data-tw-merge="" className="bg-white">
              <tr data-tw-merge="" className="intro-x  py-3 px-2">
                <th
                  data-tw-merge=""
                  className="font-medium font-base font-space-grotesk  px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0"
                >
                  SNo
                </th>

                <th
                  data-tw-merge=""
                  className="font-medium font-base font-space-grotesk  px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0"
                >
                  NAME
                </th>

                <th
                  data-tw-merge=""
                  className="font-medium font-space-grotesk font-base  px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center"
                >
                  EMAIL
                </th>

                <th
                  data-tw-merge=""
                  className="font-medium font-space-grotesk font-base  px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center"
                >
                  MOBILE NUMBER
                </th>
                <th
                  data-tw-merge=""
                  className="font-medium font-space-grotesk font-base  px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center"
                >
                  DATA RESOURCE
                </th>
                <th
                  data-tw-merge=""
                  className="font-medium font-space-groteskfont-base  px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center"
                >
                  SLUG
                </th>
                <th
                  data-tw-merge=""
                  className="font-medium font-space-grotesk font-base  px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center"
                >
                  TIME STAMP
                </th>
                <th
                  data-tw-merge=""
                  className="font-medium font-space-groteskfont-base  px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center"
                >
                  MESSAGE
                </th>
                <th
                  data-tw-merge=""
                  className="font-medium font-space-groteskfont-base  px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center"
                >
                  ADD MANUAL
                </th>
              </tr>
            </thead>
            <tbody>
              {records.map((record, index) => (
                <tr key={record.id} data-tw-merge="" className="intro-x">
                  <td
                    data-tw-merge=""
                    className="px-2 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005]  first:border-l  last:border-r dark:bg-darkmode-600 font-sm"
                  >
                    {index + 1}
                  </td>
                  <td
                    data-tw-merge=""
                    className="px-2 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005]  first:border-l  last:border-r dark:bg-darkmode-600 font-sm"
                  >
                    <Link to={`/BDA/view-contact-lead/${record.id}`}>
                      {record.name ? record.name : "-"}
                    </Link>
                  </td>

                  <td
                    data-tw-merge=""
                    className="px-2 py-3 font-space-grotesk  border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005]  first:border-l  last:border-r dark:bg-darkmode-600 font-sm"
                  >
                    {record.email ? record.email : "-"}
                  </td>

                  <td
                    data-tw-merge=""
                    className="px-2 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005]  first:border-l  last:border-r dark:bg-darkmode-600 font-sm"
                  >
                    <div className="flex items-center justify-center space-x-2">
                      {record.isMobileVerified && (
                        <CheckCircle
                          color="green"
                          className="stroke-1.5 h-5 w-5 mr-2"
                        />
                      )}
                      {record.mobileNumber ? record.mobileNumber : "-"}
                    </div>
                  </td>
                  <td
                    data-tw-merge=""
                    className="px-2 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005]  first:border-l  last:border-r dark:bg-darkmode-600 font-sm"
                  >
                    {record.data_from_page === "Others"
                      ? "-"
                      : record.data_from_page}
                  </td>
                  <td
                    data-tw-merge=""
                    className="px-2 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005]  first:border-l  last:border-r dark:bg-darkmode-600 font-sm"
                  >
                    {record.slug ? record.slug : "-"}
                  </td>
                  <td
                    data-tw-merge=""
                    className="px-2 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005]  first:border-l  last:border-r dark:bg-darkmode-600 font-sm"
                  >
                    {adjustAndFormatTime(record.createdAt)}
                  </td>
                  <td
                    data-tw-merge=""
                    className=" contact-msg px-5 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005]  first:border-l  last:border-r dark:bg-darkmode-600 font-sm"
                    title={record?.message || "No message"}
                  >
                    {record?.message && record?.message.length > 20
                      ? `${record?.message.substring(0, 20)}...`
                      : record?.message || "No message"}
                  </td>
                  <td
                    data-tw-merge=""
                    className="px-2 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005]  first:border-l  last:border-r dark:bg-darkmode-600 font-sm"
                  >
                    {/* <Link
                      to={`/BDA/add-manual/${record.id}`}
                      className="transition duration-200 border inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary mr-2 shadow-md"
                    >
                      Add
                    </Link> */}
                    {record.addedManually ? "Yes" : "No"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      </div>
    </>
  );
};

export default BDAContactLead;
