import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  contact: [],
  leadLogs: [],
  manualContact: [],
  createLink: [],
  audio: [],
  state: "idle",
  error: null,
};

export const bdaContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_LEAD_LOGS:
      return {
        ...state,
        leadLogs: Array.isArray(action.payload) ? action.payload : [],
      };
    case actionTypes.CREATE_PAYMENT_LINK:
      return {
        ...state,
        createLink: Array.isArray(action.payload) ? action.payload : [],
      };
    case actionTypes.ADD_MANUAL_CONTACT:
      return {
        ...state,
        manualContact: Array.isArray(action.payload) ? action.payload : [],
      };
    case actionTypes.ADD_AUDIO_TO_LEAD:
      return {
        ...state,
        audio: Array.isArray(action.payload) ? action.payload : [],
      };
    case actionTypes.BDA_CONTACT_LEAD:
      return {
        ...state,
        contact: action.payload,
      };

    default:
      return state;
  }
};

export default bdaContactReducer;
