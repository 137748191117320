import * as api from "../../api";
import {
  ADD_LEAD_LOGS,
  ADD_MANUAL_CONTACT,
  BDA_CONTACT_LEAD,
  BDA_CONTACT_LEAD_BY_ID,
  GET_BDA_NOTIFICATION,
  BDA_LEAD_BY_ID,
  CREATE_PAYMENT_LINK,
  ADD_AUDIO_TO_LEAD,
} from "../../constants/actionTypes";

export const getBDAContact = (params) => async (dispatch) => {
  try {
    const { data } = await api.getBDAContact(params);
    dispatch({ type: BDA_CONTACT_LEAD, payload: data });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getBDAContactById = (id) => async (dispatch) => {
  try {
    const { data } = await api.getBDAContactById(id);
    dispatch({ type: BDA_CONTACT_LEAD_BY_ID, payload: data });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addLeadLogs = (formData) => async (dispatch) => {
  try {
    const response = await api.addLeadLogs(formData);
    dispatch({ type: ADD_LEAD_LOGS, payload: response.data });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getBDANotification = () => async (dispatch) => {
  try {
    const { data } = await api.getBDANotification();
    dispatch({ type: GET_BDA_NOTIFICATION, payload: data });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addManualContact = (logsData) => async (dispatch) => {
  try {
    const response = await api.addManualContact(logsData);
    dispatch({ type: ADD_MANUAL_CONTACT, payload: response.data });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};


export const createPaymentLink = (linkData) => async (dispatch) => {
  try {
    const response = await api.createPaymentLink(linkData);
    dispatch({ type: CREATE_PAYMENT_LINK, payload: response.data });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};


export const addAudioToLead = (formData) => async (dispatch) => {
  try {
    const id = formData.get("id");
    const response = await api.addAudioToLead(formData, id);
    dispatch({ type: ADD_AUDIO_TO_LEAD, payload: response.data });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
