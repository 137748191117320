import React, { useState, useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  Outlet,
 
} from "react-router-dom";
import {
  BarChart2,
  XCircle,
  Home,
  FileText,
  Search,
  CirclePlus,
  ChevronDown,
} from "lucide-react";
import { useDispatch } from "react-redux";
import logo from "../../assets/images/image 419.png";
import profile3 from "../../assets/post/profile-3.jpg";
import profile10 from "../../assets/post/profile-10.jpg";
import profile13 from "../../assets/post/profile-13.jpg";
import toast from "react-hot-toast";
import Navbar from "../navbar/Nabar";
import { LOGOUT_BDA } from "../../constants/actionTypes";
import { getBDANotification } from "../../actions/bdaContact/bdaContact";

const BDALayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [isMasterOpen, setIsMasterOpen] = useState(false);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };
  // console.log(location.pathname);

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const handleLogout = () => {
    dispatch({ type: LOGOUT_BDA });
    toast.success("BDA logout successfully!");
    navigate("/");
  };
  const isActive = (path) => {
    return window.location.pathname === path;
  };

  const pathToNameMap = {
    "/BDA/petition-lead": "Petition Lead",
    "/BDA/communication": "Communication",
    "/BDA/dashboard": "Dashboard",
    "/BDA/contact-lead":"Contact Lead",
    
  };

  const activeTabName = pathToNameMap[location.pathname] || "Particular Lead";

  const toggleMasterMenu = () => {
    setIsMasterOpen(!isMasterOpen);
  };
  console.log(isMasterOpen);

  // Function to show the notification
  const showNotification = (title, body) => {
    if (Notification.permission === "granted") {
      new Notification(title, {
        body: body,
        icon: "/icon.png", // Add an appropriate icon path
      });
    }
  };

  // Function to fetch notification data from the backend and schedule them
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await dispatch(getBDANotification());
      if (res.success) {
        setRecords(res.data);
        // After fetching notifications, schedule each notification based on the scheduleTime
        res.data.forEach((notification) => {
          scheduleNotification(notification);
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to schedule notification based on scheduleTime
  const scheduleNotification = (notification) => {
    const scheduleTime = new Date(notification.scheduleTime); // Get the scheduled time from the API
    const currentTime = new Date(); // Get the current time

    // Ensure the time is only compared when the schedule is in the future
    if (scheduleTime > currentTime) {
      // Calculate the delay (time difference) in milliseconds
      const delay = scheduleTime - currentTime;

      // Set a timeout to show the notification at the scheduled time
      setTimeout(() => {
        showNotification(notification.title, notification.content);
      }, delay);
    } else {
      // If the scheduled time has already passed, do not show it
      console.log("Notification skipped as it's past the schedule time.");
    }
  };

  // Function to get token from localStorage
  const getTokenFromLocalStorage = () => {
    const token = localStorage.getItem("deviceToken");
    return token;
  };

  useEffect(() => {
    const token = getTokenFromLocalStorage();
    if (token) {
      // If token exists, fetch notifications
      fetchData();
    } else {
      console.log("FCM token is not available.");
      // Optionally, trigger token generation logic or request the user to allow notifications
    }
  }, [dispatch]);

  return (
    <>
      <div className="rubick px-5 sm:px-8 py-5 before:content-[''] before:bg-gradient-to-b before:from-theme-1 before:to-theme-2 dark:before:from-darkmode-800 dark:before:to-darkmode-800 before:fixed before:inset-0 before:z-[-1]">
        {/* <!-- BEGIN: Mobile Menu --> */}
        <div
          className={`mobile-menu group top-0 inset-x-0 fixed bg-theme-1/90 z-[60] border-b border-white/[0.08] dark:bg-darkmode-800/90 md:hidden ${
            isSidebarVisible ? "mobile-menu--active" : ""
          }`}
        >
          {isSidebarVisible ? (
            ""
          ) : (
            <div
              className={`fixed top-0 inset-x-0 bg-[#1e40ad]  z-[61] shadow-sm transition-all duration-300 ease-in-out ${
                isSidebarVisible ? "hidden" : ""
              }`}
            >
              <div className="flex h-[70px] items-center px-3 sm:px-8">
                <a className="mr-auto flex" href="#">
                  <img className="w-6" src={logo} alt="image" />
                </a>
                <a
                  className="mobile-menu-toggler"
                  href="#"
                  onClick={toggleSidebar}
                >
                  <BarChart2 className="stroke-1.5 h-8 w-8 -rotate-90 transform text-white" />
                </a>
              </div>
            </div>
          )}

          <div
            className={`scrollable h-screen z-20 top-0 left-0 w-[270px] -ml-[100%] bg-primary transition-all duration-300 ease-in-out dark:bg-darkmode-800 ${
              isSidebarVisible ? "ml-0" : ""
            }`}
          >
            <a
              href="#"
              className={`fixed top-0 right-0 mt-4 mr-4 transition-opacity duration-200 ease-in-out ${
                isSidebarVisible ? "visible opacity-100" : "invisible opacity-0"
              }`}
              onClick={toggleSidebar}
            >
              <XCircle className="stroke-1.5 mobile-menu-toggler h-8 w-8 -rotate-90 transform text-white" />
            </a>
            <ul className="py-2">
              {/* <!-- BEGIN: First Child --> */}

              <div className="mb-4 text-center mt-2">
                <img className="w-24 mx-auto" src={logo} alt="image" />
              </div>
              <li>
                <Link
                  to="/BDA/dashboard"
                  className={`menu ${
                    isActive("/BDA/dashboard") ? "menu--active" : ""
                  }`}
                >
                  <div className="menu__icon">
                    <Home className="stroke-1.5 w-5 h-5" />
                  </div>
                  <div className="menu__title font-space-grotesk">
                    Dashboard
                  </div>
                </Link>
              </li>

              <li>
                <Link
                  to="/BDA/contact-lead"
                  className={`menu ${
                    isActive("/BDA/contact-lead") ? "menu--active" : ""
                  }`}
                >
                  <div className="menu__icon">
                    <FileText className="stroke-1.5 w-5 h-5" />
                  </div>
                  <div className="menu__title font-space-grotesk">
                    Contact Lead
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/BDA/communication"
                  className={`menu ${
                    isActive("/BDA/communication") ? "menu--active" : ""
                  }`}
                >
                  <div className="menu__icon">
                    <Home className="stroke-1.5 w-5 h-5" />
                  </div>
                  <div className="menu__title font-space-grotesk">
                    Communication
                  </div>
                </Link>
              </li>

              {/* <!-- END: First Child --> */}
            </ul>
          </div>
        </div>
        {/* <!-- END: Mobile Menu --> */}
        <div className="mt-[4.7rem] flex md:mt-0">
          {/* <!-- BEGIN: Side Menu --> */}
          <nav className="side-nav hidden w-[80px] overflow-x-hidden pb-16 pr-5 md:block xl:w-[230px]">
            <a className="flex items-center pt-4 pl-5 intro-x" href="/">
              <img className="w-6" src={logo} alt="image" />
              <span className="hidden ml-3 text-lg text-white xl:block font-space-grotesk">
                BDA Panel
              </span>
            </a>
            <div className="my-6 side-nav__divider"></div>
            <ul>
              <li>
                <Link
                  to="/BDA/dashboard"
                  className={`side-menu ${
                    location.pathname === "/BDA/dashboard"
                      ? "side-menu--active"
                      : ""
                  }`}
                >
                  <div className="side-menu__icon">
                    <Home className="stroke-1.5 w-5 h-5" />
                  </div>
                  <div className="side-menu__title font-space-grotesk">
                    Dashboard
                  </div>
                </Link>
              </li>

              <li>
                <Link
                  to="/BDA/contact-lead"
                  className={`side-menu ${
                    location.pathname === "/BDA/contact-lead"
                      ? "side-menu--active"
                      : ""
                  }`}
                >
                  <div className="side-menu__icon">
                    <FileText className="stroke-1.5 w-5 h-5" />
                  </div>
                  <div className="side-menu__title font-space-grotesk">
                    Contact Lead
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/BDA/petition-lead"
                  className={`side-menu ${
                    location.pathname === "/BDA/petition-lead"
                      ? "side-menu--active"
                      : ""
                  }`}
                >
                  <div className="side-menu__icon">
                    <FileText className="stroke-1.5 w-5 h-5" />
                  </div>
                  <div className="side-menu__title font-space-grotesk">
                    Petition Lead
                  </div>
                </Link>
              </li>

              <li>
                <Link
                  to="/BDA/communication"
                  className={`side-menu ${
                    location.pathname === "/BDA/communication"
                      ? "side-menu--active"
                      : ""
                  }`}
                >
                  <div className="side-menu__icon">
                    <FileText className="stroke-1.5 w-5 h-5" />
                  </div>
                  <div className="side-menu__title font-space-grotesk">
                    Communication
                  </div>
                </Link>
              </li>
            </ul>
          </nav>
          {/* <!-- END: Side Menu -->
            <!-- BEGIN: Content --> */}
          <div className="md:max-w-auto min-h-screen min-w-0 max-w-full flex-1 rounded-[30px] bg-slate-100 px-4 pb-10 before:block before:h-px before:w-full before:content-[''] dark:bg-darkmode-700 md:px-[22px]">
            {/* <!-- BEGIN: Top Bar --> */}
            <div className="relative z-[51] flex h-[67px] items-center border-b border-slate-200">
              {/* <!-- BEGIN: Breadcrumb --> */}
              <nav
                aria-label="breadcrumb"
                className="flex -intro-x mr-auto hidden sm:flex"
              >
                <ol className="flex items-center text-theme-1 dark:text-slate-300">
                  <li className="font-space-grotesk">
                    <a href="#">Application</a>
                  </li>
                  <li className="relative font-space-grotesk ml-5 pl-0.5 before:content-[''] before:w-[14px] before:h-[14px] before:bg-chevron-black before:transform before:rotate-[-90deg] before:bg-[length:100%] before:-ml-[1.125rem] before:absolute before:my-auto before:inset-y-0 dark:before:bg-chevron-white text-slate-800 cursor-text dark:text-slate-400">
                    <a href="#">{activeTabName}</a>
                  </li>
                </ol>
              </nav>
              {/* <!-- END: Breadcrumb -->
                    <!-- BEGIN: Search --> */}
              <div className="search intro-x relative mr-3 sm:mr-6">
                <div className="relative hidden sm:block">
                  <input
                    data-tw-merge=""
                    type="text"
                    placeholder="Search..."
                    className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent ease-in-out text-sm placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-opacity-40 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 w-56 rounded-full border-transparent bg-slate-300/50 pr-8 shadow-none transition-[width] duration-300 focus:w-72 focus:border-transparent dark:bg-darkmode-400/70"
                  />

                  <Search className="stroke-1.5 w-5 h-5 absolute inset-y-0 right-0 my-auto mr-3 text-slate-600 dark:text-slate-500" />
                </div>
                <a className="relative text-slate-600 sm:hidden" href="#">
                  <Search className="stroke-1.5 w-5 h-5 dark:text-slate-500" />
                </a>
                <div
                  data-transition=""
                  data-selector=".show"
                  data-enter="transition-all ease-linear duration-150"
                  data-enter-from="mt-5 invisible opacity-0 translate-y-1"
                  data-enter-to="mt-[3px] visible opacity-100 translate-y-0"
                  data-leave="transition-all ease-linear duration-150"
                  data-leave-from="mt-[3px] visible opacity-100 translate-y-0"
                  data-leave-to="mt-5 invisible opacity-0 translate-y-1"
                  className="search-result absolute right-0 z-10 mt-[3px] hidden"
                >
                  <div className="box w-[450px] p-5">
                    <div className="mb-2 font-medium">Pages</div>
                    <div className="mb-5">
                      <a className="flex items-center" href="#">
                        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-success/20 text-success dark:bg-success/10">
                          <i
                            data-tw-merge=""
                            data-lucide="inbox"
                            className="stroke-1.5 h-4 w-4"
                          ></i>
                        </div>
                        <div className="ml-3">Mail Settings</div>
                      </a>
                      <a className="mt-2 flex items-center" href="#">
                        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-pending/10 text-pending">
                          <i
                            data-tw-merge=""
                            data-lucide="users"
                            className="stroke-1.5 h-4 w-4"
                          ></i>
                        </div>
                        <div className="ml-3">Users & Permissions</div>
                      </a>
                      <a className="mt-2 flex items-center" href="#">
                        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-primary/10 text-primary/80 dark:bg-primary/20">
                          <i
                            data-tw-merge=""
                            data-lucide="credit-card"
                            className="stroke-1.5 h-4 w-4"
                          ></i>
                        </div>
                        <div className="ml-3">Transactions Report</div>
                      </a>
                    </div>
                    <div className="mb-2 font-medium">Users</div>
                    <div className="mb-5">
                      <a className="mt-2 flex items-center" href="#">
                        <div className="image-fit h-8 w-8">
                          <img
                            className="rounded-full"
                            src={profile3}
                            alt="image"
                          />
                        </div>
                        <div className="ml-3">Kevin Spacey</div>
                        <div className="ml-auto w-48 truncate text-right text-xs text-slate-500">
                          kevinspacey@left4code.com
                        </div>
                      </a>
                      <a className="mt-2 flex items-center" href="#">
                        <div className="image-fit h-8 w-8">
                          <img
                            className="rounded-full"
                            src={profile10}
                            alt="image"
                          />
                        </div>
                        <div className="ml-3">Tom Cruise</div>
                        <div className="ml-auto w-48 truncate text-right text-xs text-slate-500">
                          tomcruise@left4code.com
                        </div>
                      </a>
                      <a className="mt-2 flex items-center" href="#">
                        <div className="image-fit h-8 w-8">
                          <img
                            className="rounded-full"
                            src={profile13}
                            alt="image"
                          />
                        </div>
                        <div className="ml-3">Kate Winslet</div>
                        <div className="ml-auto w-48 truncate text-right text-xs text-slate-500">
                          katewinslet@left4code.com
                        </div>
                      </a>
                      <a className="mt-2 flex items-center" href="#">
                        <div className="image-fit h-8 w-8">
                          <img
                            className="rounded-full"
                            src={profile13}
                            alt="image"
                          />
                        </div>
                        <div className="ml-3">Sylvester Stallone</div>
                        <div className="ml-auto w-48 truncate text-right text-xs text-slate-500">
                          sylvesterstallone@left4code.com
                        </div>
                      </a>
                    </div>
                    <div className="mb-2 font-medium">Products</div>
                    <a className="mt-2 flex items-center" href="#">
                      <div className="image-fit h-8 w-8">
                        <img
                          className="rounded-full"
                          src={profile10}
                          alt="image"
                        />
                      </div>
                      <div className="ml-3">Sony Master Series A9G</div>
                      <div className="ml-auto w-48 truncate text-right text-xs text-slate-500">
                        Electronic
                      </div>
                    </a>
                    <a className="mt-2 flex items-center" href="#">
                      <div className="image-fit h-8 w-8">
                        <img
                          className="rounded-full"
                          src={profile3}
                          alt="image"
                        />
                      </div>
                      <div className="ml-3">Nike Tanjun</div>
                      <div className="ml-auto w-48 truncate text-right text-xs text-slate-500">
                        Sport & Outdoor
                      </div>
                    </a>
                    <a className="mt-2 flex items-center" href="#">
                      <div className="image-fit h-8 w-8">
                        <img
                          className="rounded-full"
                          src={profile3}
                          alt="image"
                        />
                      </div>
                      <div className="ml-3">Apple MacBook Pro 13</div>
                      <div className="ml-auto w-48 truncate text-right text-xs text-slate-500">
                        PC & Laptop
                      </div>
                    </a>
                    <a className="mt-2 flex items-center" href="#">
                      <div className="image-fit h-8 w-8">
                        <img
                          className="rounded-full"
                          src={profile13}
                          alt="image"
                        />
                      </div>
                      <div className="ml-3">Nike Tanjun</div>
                      <div className="ml-auto w-48 truncate text-right text-xs text-slate-500">
                        Sport & Outdoor
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <Navbar
                handleLogout={handleLogout}
                showNotifications={showNotifications}
                toggleNotifications={toggleNotifications}
                data={records}
              />
              {/* <!-- END: Account Menu --> */}
            </div>
            {/* <!-- END: Top Bar --> */}
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12 2xl:col-span-9">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12 mt-2 lg:col-span-12 xl:col-span-12">
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- END: Content --> */}
        </div>
      </div>
    </>
  );
};

export default BDALayout;
