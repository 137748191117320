import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import toast from "react-hot-toast";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-quill/dist/quill.snow.css";
import "tailwindcss/tailwind.css";
import { useDispatch } from "react-redux";
import Loader from "../../shared/Loader";
import { useParams } from "react-router-dom";
import {
  addPetitionLeadLogs,
  getBDAPetitionById,
} from "../../actions/bdaPetition/bdaPetition";

const ParticularPetitionLead = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isNextCall, setIsNextCall] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await dispatch(getBDAPetitionById(id));
        if (res.success) {
          setRecords([res.data]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error(error?.response?.data?.message || "Something went wrong!");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, id]);

  const handleCheckboxChange = (event) => {
    setIsNextCall(event.target.checked);
  };

  const validationSchema = Yup.object().shape({
    leadCategory: Yup.object()
      .nullable()
      .required("Please select a lead category"),
    callStatus: Yup.object().nullable().required("Please select a call status"),
    legalDomain: Yup.object().nullable().required("Please select a option"),
  });
  const options = [
    { value: "hotLead", label: "Hot Lead" },
    { value: "warmLead", label: "Warm Lead" },
    { value: "coldLead", label: "Cold Lead" },
    { value: "newLead", label: "New Lead" },
  ];

  const optionsCallStatus = [
    { value: "closed", label: "Closed" },
    { value: "newLead", label: "New Lead" },
    { value: "converted", label: "Converted" },
    { value: "interested", label: "Interested" },
    { value: "doNotContact", label: "Do Not Contact" },
    { value: "followUpScheduled", label: "Follow-Up Scheduled" },
  ];

  const legalDomainOptions = [
    { value: "civil", label: "Civil" },
    { value: "criminal", label: "Criminal" },
    { value: "matrimonialDispute", label: "Matrimonial Dispute" },
    { value: "financialIrregularities", label: "Financial Irregularities" },
  ];

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loader />
      </div>
    );
  }

  const handleSubmit = async (values, { resetForm }) => {
    console.log(values);
    setLoading(true);

    let gmtDateTime = null; // Initialize utcDateTime to null

    if (startDate && selectedTime) {
      // Combine date and time into a single Date object
      const combinedDateTime = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        selectedTime.getHours(),
        selectedTime.getMinutes()
      );

      const gmtOffset = combinedDateTime.getTimezoneOffset() * 60000; // Offset in milliseconds
      gmtDateTime = new Date(combinedDateTime.getTime() - gmtOffset)
        .toISOString()
        .replace("Z", "");
    }

    console.log(gmtDateTime);

    try {
      const payload = {
        callStatus: values.callStatus.value,
        leadCategory: values.leadCategory.value,
        legalDomain: values.legalDomain.value,
        isNextCall,
        mDPFLeadId: id,
        ...(gmtDateTime && { nextCallTime: gmtDateTime }), // Only include if utcDateTime is not null
        ...(values.comment &&
          values.comment.trim() && { comment: values.comment }), // Only include if comment is not empty
      };

      const res = await dispatch(addPetitionLeadLogs(payload));
      if (res.success) {
        toast.success(res.message);
        setSelectedTime(null);
        setStartDate(null);
        setIsNextCall(false);
        resetForm();
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h2 className="intro-y mt-10 text-lg font-medium font-space-grotesk">
        User Lead
      </h2>

      <div className="mt-2 grid gap-6">
        <div className="intro-y col-span-12 overflow-x-auto max-w-full custom-scroll mt-5">
          <table
            data-tw-merge=""
            className="w-full text-left -mt-2 border-separate border-spacing-y-[10px]"
          >
            <thead data-tw-merge="" className="">
              <tr data-tw-merge="" className="">
                <th
                  data-tw-merge=""
                  className="font-medium font-base font-space-grotesk  px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center"
                >
                  NAME
                </th>

                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                  EMAIL
                </th>
                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                  CONTACT NUMBER
                </th>
                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                  ANY CHILD
                </th>
                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                  DATE LAST RESIDED TOGETHER
                </th>

                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                  MARRIAGE DATE
                </th>
                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                  PLACE LAST RESIDED TOGETHER
                </th>
                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                  REASON FOR DIVORCE
                </th>
                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                  SLUG
                </th>
                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                  TERM ACCEPTED
                </th>
                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                  TOTAL PAYABLE AMOUNT
                </th>
                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                  YOU ARE
                </th>
                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                  SPOUSE NAME
                </th>
                <th className="font-medium font-base font-space-grotesk px-2 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center">
                  CREATED AT
                </th>
              </tr>
            </thead>
            <tbody>
              {records.map((record) => (
                <tr key={record.id} className="intro-x">
                  <td
                    data-tw-merge=""
                    className="px-2 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm text-center"
                  >
                    {record.name || "-"}
                  </td>
                  <td
                    data-tw-merge=""
                    className="px-2 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm text-center"
                  >
                    {record.your_email || "-"}
                  </td>
                  <td
                    data-tw-merge=""
                    className="px-2 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm text-center"
                  >
                    {record.your_contact_number || "-"}
                  </td>
                  <td
                    data-tw-merge=""
                    className="px-2 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm text-center"
                  >
                    {record.anyChild ? "Yes" : "No"}
                  </td>
                  <td
                    data-tw-merge=""
                    className="px-2 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm text-center"
                  >
                    {record.date_last_resided_together || "-"}
                  </td>

                  <td
                    data-tw-merge=""
                    className="px-2 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm text-center"
                  >
                    {record.marriage_date || "-"}
                  </td>
                  <td
                    data-tw-merge=""
                    className="px-2 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm text-center"
                  >
                    {record.place_last_resided_together || "-"}
                  </td>
                  <td
                    data-tw-merge=""
                    className="px-2 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm text-center"
                  >
                    {record.reason_for_divorce || "-"}
                  </td>
                  <td
                    data-tw-merge=""
                    className="px-2 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm text-center"
                  >
                    {record.slug || "-"}
                  </td>
                  <td
                    data-tw-merge=""
                    className="px-2 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm text-center"
                  >
                    {record.term_accepted ? "Yes" : "No"}
                  </td>
                  <td
                    data-tw-merge=""
                    className="px-2 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm text-center"
                  >
                    {record.total_payable_amount || "-"}
                  </td>
                  <td
                    data-tw-merge=""
                    className="px-2 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm text-center"
                  >
                    {record.you_are || "-"}
                  </td>
                  <td
                    data-tw-merge=""
                    className="px-2 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm text-center"
                  >
                    {record.spouse_name || "-"}
                  </td>
                  <td
                    data-tw-merge=""
                    className="px-2 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-sm text-center"
                  >
                    {new Date(record.createdAt).toLocaleString() || "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Formik
        initialValues={{
          parentCategory: null,
          callStatus: null,
          comment: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form>
            <h2 className="intro-y mt-10 text-lg font-medium font-space-grotesk">
              Lead Logs
            </h2>
            <div className="mt-5 grid grid-cols-12 gap-6">
              <div className="intro-y col-span-12">
                <div className="intro-y box p-5">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <div>
                      <label
                        htmlFor="post-form-3"
                        className="inline-block font-space-grotesk mb-2"
                      >
                        Lead Categories
                      </label>
                      <Field name="leadCategory">
                        {({ field }) => (
                          <Select
                            id="tag-select"
                            options={options}
                            onChange={(option) =>
                              setFieldValue("leadCategory", option)
                            }
                            classNamePrefix="tag-select"
                            className={`w-full ${
                              errors.leadCategory && touched.leadCategory
                                ? "border-red-500"
                                : ""
                            }`}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="leadCategory"
                        component="div"
                        className="text-red-500 mt-1 text-sm"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="post-form-3"
                        className="inline-block font-space-grotesk mb-2"
                      >
                        Call Status
                      </label>
                      <Field name="callStatus">
                        {({ field }) => (
                          <Select
                            id="tag-select"
                            options={optionsCallStatus}
                            onChange={(option) =>
                              setFieldValue("callStatus", option)
                            }
                            classNamePrefix="tag-select"
                            className={`w-full ${
                              errors.callStatus && touched.callStatus
                                ? "border-red-500"
                                : ""
                            }`}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="callStatus"
                        component="div"
                        className="text-red-500 mt-1 text-sm"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="post-form-3"
                        className="inline-block font-space-grotesk mb-2"
                      >
                        Legal Domain
                      </label>
                      <Field name="legalDomain">
                        {({ field }) => (
                          <Select
                            id="tag-select"
                            options={legalDomainOptions}
                            onChange={(option) =>
                              setFieldValue("legalDomain", option)
                            }
                            classNamePrefix="tag-select"
                            className={`w-full ${
                              errors.legalDomain && touched.legalDomain
                                ? "border-red-500"
                                : ""
                            }`}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="legalDomain"
                        component="div"
                        className="text-red-500 mt-1 text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="comment" className="inline-block mb-2">
                        Comment
                      </label>

                      <Field
                        as="textarea"
                        id="description"
                        name="description"
                        onChange={(e) => {
                          const { value } = e.target;
                          setFieldValue("comment", value);
                        }}
                        placeholder="Enter Comment"
                        className={`w-full text-sm border-slate-200 shadow-sm rounded-md p-2 placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary 
                      `}
                        style={{ height: "100px" }}
                      />
                    </div>

                    {isNextCall && (
                      <div>
                        <label
                          data-tw-merge=""
                          for="post-form-2"
                          className="inline-block font-space-grotesk mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right"
                        >
                          Post Date
                        </label>

                        {/* <div>
                          <DatePicker
                            placeholderText="Select Date"
                            selected={startDate}
                            onChange={(date) => {
                              setStartDate(date);
                              setFieldValue("selectedDate", date);
                            }}
                            dateFormat="yyyy-MM-dd"
                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                          />

                          <ErrorMessage
                            name="selectedDate"
                            component="div"
                            className="text-red-500 mt-2"
                          />
                        </div> */}
                        <div>
                          <DatePicker
                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                            placeholderText="Select Date"
                            selected={startDate}
                            onChange={(date) => {
                              setStartDate(date);
                              setFieldValue("selectedDate", date); // Save as UTC format
                            }}
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                        <div className="mt-3">
                          <label className="inline-block mb-2">
                            Select Time
                          </label>
                          {/* <DatePicker
                            placeholderText="Select Time"
                            selected={selectedTime}
                            onChange={(time) => {
                              setSelectedTime(time);
                              setFieldValue("selectedTime", time);
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="HH:mm"
                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                          /> */}
                          <DatePicker
                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                            placeholderText="Select Time"
                            selected={selectedTime}
                            onChange={(time) => {
                              setSelectedTime(time);
                              setFieldValue("selectedTime", time);
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="HH:mm"
                          />
                          <ErrorMessage
                            name="selectedTime"
                            component="div"
                            className="text-red-500 mt-2"
                          />
                        </div>
                      </div>
                    )}

                    <div
                      data-tw-merge=""
                      className="flex mt-3 flex-col items-start"
                    >
                      <label
                        data-tw-merge=""
                        for="post-form-5"
                        className="cursor-pointer mb-2 ml-0 font-space-grotesk"
                      >
                        Next Call
                      </label>

                      <input
                        type="checkbox"
                        id="post-form-5"
                        checked={isNextCall}
                        onChange={handleCheckboxChange}
                        className="transition-all duration-100 ease-in-out shadow-sm border-slate-200 cursor-pointer focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-20 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&[type='radio']]:checked:bg-primary [&[type='radio']]:checked:border-primary [&[type='radio']]:checked:border-opacity-10 [&[type='checkbox']]:checked:bg-primary [&[type='checkbox']]:checked:border-primary [&[type='checkbox']]:checked:border-opacity-10 [&:disabled:not(:checked)]:bg-slate-100 [&:disabled:not(:checked)]:cursor-not-allowed [&:disabled:not(:checked)]:dark:bg-darkmode-800/50 [&:disabled:checked]:opacity-70 [&:disabled:checked]:cursor-not-allowed [&:disabled:checked]:dark:bg-darkmode-800/50 w-[38px] h-[24px] p-px rounded-full relative before:w-[20px] before:h-[20px] before:shadow-[1px_1px_3px_rgba(0,0,0,0.25)] before:transition-[margin-left] before:duration-200 before:ease-in-out before:absolute before:inset-y-0 before:my-auto before:rounded-full before:dark:bg-darkmode-600 checked:bg-primary checked:border-primary checked:bg-none before:checked:ml-[14px] before:checked:bg-white"
                      />
                    </div>
                  </div>

                  <div className="mt-5 text-right">
                    <button
                      disabled={loading}
                      type="submit"
                      className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 bg-primary border-primary text-white w-24"
                    >
                      {loading ? "Saving..." : "Save"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ParticularPetitionLead;
