export const LOGIN = "LOGIN";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const ADD_PARENT_CATEGORY = "ADD_PARENT_CATEGORY";
export const GET_PARENT_CATEGORY = "GET_PARENT_CATEGORY";
export const CHECK_PARENT_SLUG_CATEGORY = "CHECK_PARENT_SLUG_CATEGORY";
export const CHECK_CATEGORY_SLUG = "CHECK_CATEGORY_SLUG";
export const ADD_TAG = "ADD_TAG";
export const CHECK_TAG_SLUG = "CHECK_TAG_SLUG";
export const GET_TAG = "GET_TAG";
export const DELETE_TAG = "DELETE_TAG";
export const ADD_BLOG = "ADD_BLOG";
export const CHECK_BLOG_SLUG='CHECK_BLOG_SLUG';
export const GET_CATEGORY='GET_CATEGORY';

export const DELETE_PARENT_CATEGORY='DELETE_PARENT_CATEGORY';
export const DELETE_CATEGORY='DELETE_CATEGORY';
export const EDIT_PARENT_CATEGORY='EDIT_PARENT_CATEGORY';
export const GET_PARENT_CATEGORY_BY_SLUG='GET_PARENT_CATEGORY_BY_SLUG';
export const GET_CATEGORY_BY_SLUG='GET_CATEGORY_BY_SLUG';
export const EDIT_CATEGORY='EDIT_CATEGORY';

export const EDIT_TAG='EDIT_TAG';
export const GET_TAG_BY_SLUG='GET_TAG_BY_SLUG';
export const GET_BLOG='GET_BLOG';
export const GET_BLOG_BY_SLUG='GET_BLOG_BY_SLUG';
export const UPDATE_BLOG='UPDATE_BLOG';
export const DELETE_BLOG='DELETE_BLOG';
export const PUBLISH_BLOG='PUBLISH_BLOG';
export const DELETE_BLOG_IMAGE='DELETE_BLOG_IMAGE';
export const ADD_FEATURED_IMAGE='ADD_FEATURED_IMAGE';
export const ADD_ADDITIONAL_IMAGE='ADD_ADDITIONAL_IMAGE';


export const BDA_CONTACT_LEAD='BDA_CONTACT_LEAD';
export const BDA_CONTACT_LEAD_BY_ID='BDA_CONTACT_LEAD_BY_ID';
export const ADD_LEAD_LOGS='ADD_LEAD_LOGS';
export const GET_BDA_NOTIFICATION='GET_BDA_NOTIFICATION';
export const BDA_LEAD_BY_ID='BDA_LEAD_BY_ID';

export const BDA_PETITION_LEAD='BDA_PETITION_LEAD';
export const BDA_PETITION_LEAD_BY_ID='BDA_PETITION_LEAD_BY_ID';
export const ADD_PETITION_LEAD_LOGS='ADD_PETITION_LEAD_LOGS';

export const DELETE_BLOG_CATEGORY='DELETE_BLOG_CATEGORY';
export const UPDATE_BLOG_CATEGORY='UPDATE_BLOG_CATEGORY';

export const DELETE_BLOG_TAG='DELETE_BLOG_TAG';
export const UPDATE_BLOG_TAG='UPDATE_BLOG_TAG';


export const LOGOUT_BDA='LOGOUT_BDA';
export const LOGOUT_BLOGGER='LOGOUT_BLOGGER';
export const TOGGLE_NOTIFICATION = "TOGGLE_NOTIFICATION";

export const ADD_MANUAL_CONTACT='ADD_MANUAL_CONTACT';
export const CREATE_PAYMENT_LINK='CREATE_PAYMENT_LINK';
export const ADD_AUDIO_TO_LEAD='ADD_AUDIO_TO_LEAD';
