import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import toast from "react-hot-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-quill/dist/quill.snow.css";
import "tailwindcss/tailwind.css";
import { useDispatch } from "react-redux";
import Loader from "../../shared/Loader";
import { addManualContact } from "../../actions/bdaContact/bdaContact";

const AddManualLead = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);

  const validationSchema = Yup.object().shape({
    mobileNumber: Yup.string()
      .required("Mobile number is required")
      .matches(/^\d{10}$/, "Mobile number must be 10 digits"),
    selectedDate: Yup.date().nullable().required("Date is required"),
  });

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loader />
      </div>
    );
  }

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async (values, { resetForm }) => {
    console.log(values);
    setLoading(true);

    try {
      const selectedDate = values.selectedDate;

      // Use the custom formatter
      const formattedDate = selectedDate ? formatDate(selectedDate) : null;

      const payload = {
        mobileNumber: values.mobileNumber,
        createdAt: formattedDate,
      };

      const res = await dispatch(addManualContact(payload));
      if (res.success) {
        toast.success(res.message);
        setStartDate(null);
        resetForm();
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          mobileNumber:"",
          selectedDate: startDate,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <Form>
            <h2 className="intro-y mt-10 text-lg font-medium font-space-grotesk">
              Add Manually
            </h2>
            <div className="mt-5 grid grid-cols-12 gap-6">
              <div className="intro-y col-span-12">
                <div className="intro-y box p-5">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <div>
                      <label
                        htmlFor="mobileNumber"
                        className="inline-block mb-2"
                      >
                        Mobile Number
                      </label>

                      <Field
                        as="input"
                        type="tel"
                        id="mobileNumber"
                        name="mobileNumber"
                        onChange={(e) => {
                          const { value } = e.target;
                          setFieldValue("mobileNumber", value);
                        }}
                        placeholder="Enter Mobile Number"
                        className={`w-full text-sm border-slate-200 shadow-sm rounded-md p-2 placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary`}
                        maxLength={10}
                      />
                      <ErrorMessage
                        name="mobileNumber"
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="selectedDate"
                        className="inline-block mb-2"
                      >
                        Date
                      </label>
                      <div>
                        <DatePicker
                          className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                          placeholderText="Select Date"
                          selected={startDate}
                          onChange={(date) => {
                            setStartDate(date);
                            setFieldValue("selectedDate", date); // Save as UTC format
                          }}
                          dateFormat="yyyy-MM-dd"
                        />
                        <ErrorMessage
                          name="selectedDate"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 text-right">
                    <button
                      disabled={loading}
                      type="submit"
                      className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 bg-primary border-primary text-white w-24"
                    >
                      {loading ? "Saving..." : "Save"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddManualLead;
