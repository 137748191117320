import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  petitionLead: [],
  lead: [],
  state: "idle",
  error: null,
};

export const bdaPetitionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_PETITION_LEAD_LOGS:
      return {
        ...state,
        lead: Array.isArray(action.payload) ? action.payload : [],
      };
    case actionTypes.BDA_PETITION_LEAD:
      return {
        ...state,
        petitionLead: action.payload,
      };

    default:
      return state;
  }
};

export default bdaPetitionReducer;
